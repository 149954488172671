@import "src/assets/scss/core";
.container {
  display: flex;
  align-content: center;
  align-items: center;
}

.icon_title {
  display: flex;
  align-items: center;
  flex: 2;
}

.welcome_primary {
  color: $welcome-message-primary;
  padding-top: $padding-top-home;
  font-family: $roboto-regular;
  font-size: $heading-size-1;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

.welcome_secondary {
  color: $welcome-message-secondary;
  font-family: $roboto-light;
  font-size: $heading-size-3;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  padding-left: 2.3rem;
}
.welcome_secondary_local {
  color: $welcome-message-secondary;
  font-family: $roboto-light;
  font-size: $heading-size-3;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

.motivational_phrase {
  color: $welcome-message-secondary;
  font-family: $roboto-light;
  font-size: $phrase-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  padding-top: $padding-top-home;
  padding-left: $padding-left-home;
  flex: 1;
}
.icon {
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  color: #171f5e;
  font-size: 1px;
}

.content_card {
  display: flex;
  flex-direction: column;
  .welcome_primary,
  .welcome_secondary {
    padding-left: 10px;
  }
}

.title {
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: 0;
  }

  .motivational_phrase {
    padding-top: 0;
    padding-left: 0;
  }

  .welcome_primary {
    font-size: 1.6rem;
    justify-content: center;
  }

  .welcome_secondary {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  .icon_title {
    justify-content: center;
    padding-top: 1.5rem;
    margin-left: 4px;
  }
}
