#buttonS {
  width: 15px;
  background-color: white;
  height: 15px;
}
#Selector {
  color: black;
  font-weight: bold;
  font-size: 20px;
}
#contextBtn {
  width: 150px;
  margin-top: 5px;
  border-radius: 5px;
}
#simple-popover{
  left: -150px;
  top: 10px;
}
#select {
  width: 150px;
  font-size: 12px;
  border-radius: 5px;
}
#contextIcon {
    position: absolute;
    left: 23px;
    margin-top: 3.75px;
}

.css-26l3qy-menu > *{
  background-color: white !important;
}

.css-26l3qy-menu {
  padding: 0 !important;
  margin: 0 !important;
  height: -10% !important;
  width: 110% !important;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none !important;
}

.fc-event-title:blank{
  top:-26px;
}


