@import "src/assets/scss/core";
.field {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat
      no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 4px 20px;
    background-position: 92%;
    height: 39px;
    width: 253px;
    border: 0.4px solid $select-border;
    border-radius: 3px;
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: "Roboto";
    line-height: 99.19%;
    letter-spacing: 0.0015rem;
  }
}
