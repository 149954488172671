.component {
  width: 25px;
  display: flex;
  justify-content: center;
  height: 25px;
  left: 595px;
  top: 124px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  align-items: center;
}
