@import "../../assets/scss/variables";

.main-projects {
  padding: 0px 50px 0 20px;
  margin-top: 26px;
  .projects {
    font-family: "Roboto-Regular";
  }
  .flex-header {

    justify-content: space-between;
    background-color: transparent;
  }
  .projects-list {
    width: 100%;
    padding: 10px 0;
    position: relative;
  }

  .project-list-search {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 0;
    .search-project {
      height: 120%;
    }
    ::placeholder {
      font-family: $roboto-regular;
      font-size: $small-text-size;
    }
    button {
      color: white;
      font-size: 13px;
      background: $btn-happy;
      border-radius: 5px;
      border: 0;
    }
    .buttonModal {
      width: 114px;
      height: 31px;
      text-align: center;
    }
    p {
      margin: auto;
      font-family: $roboto-regular;
      font-size: $small-text-size;
      font-weight: 400;
    }
  }

  .search-project {
    display: flex;
    justify-content: start;
    align-content: center;
    padding: 5px;
    color: $dark-grey;

    ::placeholder {
      font-family: $roboto-regular;
      font-size: $small-text-size;
    }
    .btnclose {
      color: grey;
      font-size: 13px;
      background: transparent;
      border-radius: 5px;
      border: 0;
      padding-right: 10px;
    }
    p {
      margin: auto 5px;
      margin-left: 15px;
      font-family: $roboto-regular;
      font-size: $small-text-size;
    }
  }
}

.folder svg {
  width: 20px;
  height: 20px;
  margin-right: 10.5px;
  color: $welcome-message-primary;
}
.title-conteiner {
  display: flex;
  align-items: flex-start;
  font-family: $roboto-medium;
  font-size: 24px;
}

.position {
  display: flex;
  justify-content: flex-end;

  .checkbox {
    align-items: center;
    margin-right: 2px;
  }
  .checkbox label {
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: $roboto-regular;
    font-size: $small-text-size;
    font-weight: bold;
  }
  button {
    width: 100%;
  }
}

.head-table {
  font-family: $roboto-bold;
  font-size: $heading-size-2;
}
.filterName {
  margin: 25px;
}
.project-list-table {
  display: grid;
  grid-template-columns: 38% 17% 35% 10%;
  align-items: center;
  border-bottom: 2px solid #e9e9e9;
  padding: 10px;

  .info-project {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .img-project {
      height: 46px;
      margin: 12px;
    }

    .name-project {
      width: 100%;
      padding-left: 10px;
    }

    .name-project p {
      width: inherit;
    }

    .title-project {
      font-size: 18px;
      color: black;
    }

    .description-project {
      font-size: 16px;
    }
  }
  .status-project-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .info-status {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: auto;

      .status-1 {
        padding-left: 5px;
        color: #28a745;
        font-weight: 500;
        font-size: $heading-size-3;
      }

      .status-2 {
        padding-left: 5px;
        color: #dc3545;
        font-weight: 500;
        font-size: $heading-size-3;
      }

      .status-3 {
        padding-left: 5px;
        color: #ffc107;
        font-weight: 500;
        font-size: $heading-size-3;
      }

      .status-outdated,
      .status-4 {
        padding-left: 5px;
        color: #6315b1;
        font-weight: 500;
        font-size: $heading-size-3;
      }
    }
    .date {
      text-align: center;
      color: rgb(173, 171, 171);
      font-size: $heading-size-4;
    }

    .info-status-mood {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: auto;
    }

    .info-status-satisfaction {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: auto;
    }
  }

  .puntos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    .date {
      text-align: center;
    }
  }
}
.version {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #F8F7F7;
  width: 136px;
  height: 45px;
}
.versionText{
  margin-top: 15px;
  font-family: 'Inter';
  text-align: center;
  padding: 2px;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #CFCFCF;
}
p {
  margin-bottom: 0;
}

@media only screen and (max-width: $maxMobilePhone-width) {
  .main-projects {
    padding: 0 0 0 0 !important;
    .project-list-search{
      display: flex;
      grid-template-columns: 70% 30%;
      align-items: center;
      flex-direction: column;
    }
    .project-list-table {
      grid-template-columns: 33% 22% 35% 10%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .info-project {
        .img-project {
          display: none;
        }
        .name-project {
          padding-left: 5px;
        }
  
        .title-project {
          font-size: 16px;
        }
  
        .description-project {
          font-size: 14px;
        }
      }
  
      .status-project-list {
        .info-status {
          display: flex;
          align-items: center;
          justify-content: center;
          .status-1 {
            font-size: 15px;
          }
          .status-2 {
            font-size: 15px;
          }
          .status-3 {
            font-size: 15px;
          }
          .status-outdated,
          .status-4 {
            font-size: 15px;
          }
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .date {
          font-size: 15px;
        }
      }
    }
  }
  
}
