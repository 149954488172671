@import "src/assets/scss/core";
.clock_container {
  display: flex;
  flex-direction: row;
  width: 600px; //temporary size, must be 100%
  height: 100%;

  .clock_principal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
  }
  .circularClockIcon {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .clockLocation {
    margin-top: 10%;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #171f5e;
    line-height: 99.19%;
    letter-spacing: 0.04em;
    margin-bottom: 3%;
  }

  .main_clock {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 99.19%;
    letter-spacing: 0.04em;
    color: #1263dd;
    margin-bottom: 10%;

    .main_clocktz {
      font-weight: 600;
        font-size: 14px;
      color: #9c9c9c;
      text-align: center;
    }
  }
  .main_clocktext {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    line-height: 99.19%;
    letter-spacing: 0.04em;
    color: #6d6d6d;
    margin-bottom: 10%;
    max-width: 138px;
    span {
      // Temp color
      font-weight: 500;
      color: #17324d;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .clock_grid {
    flex: 2;
    display: grid;
    grid-template-columns: repeat(4, auto);

    gap: 1px;
    align-items: center;
    margin: 0 5% 2% 0;
  }
}

.clock_time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.miniclock_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
  padding: 0px 3px;

  .locationName {
    font-family: $roboto-regular;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #171f5e;
    margin-top: -10%;
  }

  .clockRegions {
    text-align: center;
    font-weight: 300;
    font-size: 9px;
    line-height: 99.19%;
    letter-spacing: 0.04em;
    color: #000;
    margin-top: -5%;
  }
}

@media only screen and (max-width: 850px) {
  .clock_container {
    width: auto; //temporary size, must be 100%
    flex-direction: column;
    height: 650px;
    transform: scale(0.8);
    .clock_principal {
      position: relative;
      top: -50px;
      transform: scale(1.2);
    }
    .clock_grid {
      grid-template-columns: repeat(4, max-content);
      transform: scale(1.2);
    }
  }
  #clockDropdown {
    right: 300px !important;
  }
}
