@import "src/assets/scss/core";

/*min-width and max-width are provisory*/
.dropdown-container {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  min-width: 141px;
  max-width: 600px;
  padding: 30px;
  background: $white;
  color: $grey;
  line-height: 2em;
  border-radius: $border-radius;
  box-shadow: $shadow-dropdown;
  transition: 0.3s ease-in-out all;
  margin-top: 10px;
  border-radius: 0;
  cursor: default;

  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  a {
    display: block;
    text-decoration: none;
    color: $grey;
  }
}
