@import "src/assets/scss/core";

.whatIsNextContainer {
  width: 95%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
  border-radius: $border-radius;
  margin-left: 30px;
  float: right;
.whatIsNextTitle {
    font-family: $roboto-medium;
    color: $heading-text-color;
    font-size: 22px;
    padding: 30px 30px 30px 40px;
    margin: 0;
    box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.22);
    border-radius: 9px 9px 0px 0px;
  }
  
}
