.triangle-left-up {
  position: relative;
  left: 0;
  top: 0px;
  width: 0;
  height: 0;
  border-left: 20px solid #5ac775;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.triangle-left-down {
  position: relative;
  // left: 215;
  // top: 45px;
  width: 0;
  height: 0;
  border-left: 20px solid #5ac775;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.triangle-right-up {
  position: relative;
  left: 10px;
  width: 24.85px;
  height: 15.23px;
  border-right: 20px solid #5ac775;
  border-top: 12.5px solid transparent;
  border-bottom: 12.5px solid transparent;
  z-index: 1;
}

.triangle-right-down {
  position: fixed;
  left: 80px;
  top: 0px;
  width: 0;
  height: 0;
  border-right: 20px solid #5ac775;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.square {
  position: absolute;
  left: 35px;
  width: 109px;
  background: #5ac775;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  z-index: 1;
}

.pop-button button {
  font-weight: 500;
  font-size: 12px;
  line-height: 99.19%;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.pop-button:hover {
  transition-duration: 250ms;
  background-color: #469c5b;
  cursor: pointer;
}

.disabled {
  font-weight: 500;
  font-size: 12px;
  line-height: 99.19%;
  letter-spacing: 0.02em;
  color: #5e5e5e;
}

.btn-addTime {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 5px;
  font-family: "Roboto";
  font-style: normal;
}

@media only screen and (max-width: 1536px) {
  .triangle-left-up {
    position: relative;
    left: 0;
    top: 0px;
    width: 0;
    height: 0;
    border-left: 20px solid #5ac775;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .triangle-left-down {
    position: relative;
    // left: 215;
    // top: 45px;
    width: 0;
    height: 0;
    border-left: 20px solid #5ac775;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  .triangle-right-up {
    position: relative;
    left: 10px;
    width: 24.85px;
    height: 15.23px;
    border-right: 20px solid #5ac775;
    border-top: 12.5px solid transparent;
    border-bottom: 12.5px solid transparent;
    z-index: 1;
  }

  .triangle-right-down {
    position: fixed;
    left: 80px;
    top: 0px;
    width: 0;
    height: 0;
    border-right: 20px solid #5ac775;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  .square {
    position: absolute;
    left: 33px;
    width: 109px;
    background: #5ac775;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 1;
  }

  .pop-button button {
    font-weight: 500;
    font-size: 12px;
    line-height: 99.19%;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  .pop-button:hover {
    transition-duration: 250ms;
    background-color: #469c5b;
    cursor: pointer;
  }

  .disabled {
    font-weight: 500;
    font-size: 12px;
    line-height: 99.19%;
    letter-spacing: 0.02em;
    color: #5e5e5e;
  }

  .btn-addTime {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 5px;
    font-family: "Roboto";
    font-style: normal;
  }
}
