@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap");
@import "src/assets/scss/core";

:root {
  --fc-border-color: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.scheduler-layout {
  font-family: "Roboto-regular", "sans-serif";
  background-color: #ffffff;
  z-index: 0;
  outline: none;

  .hrScheduler {
    margin: 1rem 0;
    //color: inherit
    //background-color: currentColor;
    //border: 0;
    //opacity: $hr-opacity;
    color: #F0F0F0;
    opacity: initial;
    height: 2px;
  }
}

.fc {
  background-color: #fcfcfc;
  border-radius: 5px;

  .fc-daygrid-day-bg {
    .fc-bg-event {
      border-radius: 10px;
      text-align: center;
      padding-top: 2rem;
    }
  }

  table {
    border-collapse: separate;
  }
}

.scheduler-header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  z-index: 10;

  .btnLang {
    background-color: rgb(165, 179, 172);
    height: 25px;
    width: 80px;
    font-size: 13px;
    font-size-adjust: 100%;
    border: none;
    color: white;
    border-radius: 3px;
  }

  .btnLang:hover {
    background-color: rgb(115, 126, 120);
  }

  .btnDropDown {
    background-color: rgb(255, 255, 255) !important;
    border-color: blue !important;
    padding-left: 0;
    padding-right: 0;
    color: $black;

    h4 {
      font-size: 20px;
    }

    .button {
      background-color: black !important;
    }
  }
  .btn-group-sm > .btn,
  .btn-sm {
    margin-top: -5px;
    font-size: 20px !important;
  }

  .btnDropDown:hover {
    background-color: rgb(255, 255, 255) !important;
    border-color: blue !important;
    color: black;
  }

  .btn-check:active + .btn-secondary,
  .btn-check:checked + .btn-secondary,
  .btn-secondary.active,
  .btn-secondary:active,
  .show > .btn-secondary.dropdown-toggle {
    color: black;
  }

  .gralDropDown {
    display: block !important;
    color: black;
  }

  .styleDiv {
    display: flex;
    align-items: baseline;

    h4 {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 24px;
      color: #171f5e;
      font-family: "Roboto-medium";
    }
  }

  .styleDiv1 {
    display: flex;
    align-items: baseline;
    position: relative;
    right: 230px;
    width: 286px;

    h4 {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 24px;
      color: #171f5e;
      font-family: "Roboto-medium";
    }

    #context-title {
      color: black;
    }
    #actual-context {
      font-size: 20px;
      color: black;
      font-weight: bold;
      margin-right: 15px;
    }
  }

  button {
    height: 39px;
    width: 160px;
    font-size: 13px;
    font-size-adjust: 100%;
    border: none;
    background-color: #5ac775;
    color: white;
    border-radius: 3px 3px 3px 3px;
  }

  button:hover {
    background-color: #098068;
  }
}

.scheduler {
  box-sizing: border-box !important;
  display: flex;
  padding-top: 12px;
  min-height: 50px;

  .calendar {
    color: #666666;
    min-height: 50px;
    width: 461px;

    .dashedBorder {
      border-style: dashed;
    }

    .dayOff {
      white-space: break-spaces;
      line-height: 12px;
    }

    .myCalendar {
      box-sizing: border-box;
      padding-bottom: 0;
      margin-bottom: 0;
      min-height: 50px;
      height: 472px;
      width: 500px;


      .fc-event-title {
        padding-top: calc(4px + 1vh + 2px);
        font-size: calc(4px + 0.4vw);
        font-weight: 500;
        width: 100%;
        height: 47px;
        text-align: center;
      }

      .fc-event-title:hover {
        cursor: pointer;
      }

      .fc-direction-ltr .fc-daygrid-event.fc-event-end,
      .fc-direction-ltr .fc-daygrid-event.fc-event-start {
        margin-top: calc(-20px - 0.8vh);
        border-width: 2px;
        border-radius: 8px;
      }

      table {
        padding: 0;
        max-height: fit-content;
      }

      .fc-scrollgrid-section
      .fc-scrollgrid-section-body
      .fc-scrollgrid-section-liquid {
        height: 210px !important;
      }

      .fc-day,
      .fc-daygrid-day,
      .fc-day-past,
      .fc-day-future {
        height: 52px;
        border: 0;
        z-index: 0;
      }

      .fc-daygrid-body {
        table {
          height: 22vh !important;
        }
      }
      .fc {
        background-color: #fafafa;
        border-radius: 10px;
        width: 472px;
        padding: 20px 20px 13px 20px;
        min-height: 50px;
        min-width: 280px;
        flex-grow: 1;
        height: 435px;

        .fc .fc-scrollgrid-section table {
          height: 200px !important;
        }

        .fc-daygrid-day .fc-day .fc-day-past {
          height: 20px !important;
        }

        .fc-daygrid-day .fc-day {
          height: 25px !important;
        }

        .fc-daygrid-event-harness {
          margin-top: 0 !important;
          z-index: 0;
        }

        a {
          background-color: transparent !important;
        }
        .fc-bg-event {
          opacity: 0.8;
        }

        .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
          color: #b0b0b0;
          text-decoration: none;
        }

        .fc-toolbar-title {
          font-family: "Roboto-medium";
          font-size: calc(8px + 0.5vw);
          color: #171f5e;
          margin-top: -5px;
        }

        .fc-daygrid-event {
          z-index: 1;
          white-space: unset;
        }

        .fc-daygrid-day-frame,
        .fc-scrollgrid-sync-inner {
          height: 10px;
          padding: 0;
        }

        .fc-day-future > div:hover {
          cursor: pointer;
          background: #5ac775;
          border-radius: 8px;
        }
        .fc-day-today > div:hover {
          cursor: pointer;
          background: #5ac775;
          border-radius: 8px;
        }
        .fc-day-past > div:hover {
          cursor: pointer;
          background: #5ac775;
          border-radius: 8px;
        }

        .fc-day-future > div:hover {
          cursor: pointer;
          background: #5ac775;
          border-radius: 8px;
        }

        .fc-scrollgrid-sync-inner .fc-daygrid-day-number {
          color: black;
          text-decoration: none;
          font-weight: 400;
          cursor: auto;
        }

        .fc-daygrid-day-number:hover {
          cursor: pointer;
        }

        .fc-daygrid-day-top {
          flex-direction: row;
          justify-content: center;
          font-size: calc(1vh + 4px);
          padding: 0;
          margin: 0;
        }

        .fc-day-disabled {
          background-color: transparent;
        }

        .fc-day-sun .fc-daygrid-day-number:hover {
          cursor: default;
        }
      }

      .fc-day-sun > * :hover {
        cursor: auto;
      }

      .fc-col-header-cell {
        height: 25px;
        padding-bottom: 12px;
        font-size: calc(1vh + 4px);
      }

      .fc .fc-scroller-liquid-absolute {
        overflow-y: visible !important;
        overflow: visible !important;
        top: 5px;
      }

      .fc-toolbar-chunk {
        display: flex;
        align-items: baseline;

        .fc-nextButton-button,
        .fc-prevButton-button {
          background-color: rgba(255, 255, 255, 0);
          border: none;
          font-weight: 700;
          font-size: calc(10px + 1vh);
          margin: -9px 0 0 0;
          color: #b0b0b0;
          padding: 0;

          .fc-icon {
            color: green;
          }
        }
      }

      .fc-toolbar-title {
        margin: 0;
        padding-left: 22px;
        padding-right: 22px;
      }
      .fc .fc-toolbar {
        justify-content: center;
        padding-top: 20px;
      }
      .fc-header-toolbar {
        margin: 0;
      }
    }

    .informationScheduler {
      font-size: calc(4px + 0.4vw);
      color: black;
      font-style: normal;
      padding-top: 13px;
      margin-bottom: 0%;

      svg {
        width: calc(6px + 0.5vh);
        margin-right: 0.5vw;
      }
    }

    .myDaysOf {
      background-color: rgba(255, 255, 255, 0);
      font-weight: 500;
      text-align: center;
      text-align: center;
      overflow-y: scroll;
      max-height: calc(
              100vh - 10vh - 10vh - 29px - 20px - 33vh - 5vh - 5vh - 30px
      );
      // Total height - topbar - Scheduler Header - header margin bottom - tolerancy - calendar - total time - available off - pad top
    }

    .totalTimeReported {
      display: inline-flex;
      background-color: rgba(255, 255, 255, 0);
      font-weight: 500;
      text-align: left;
      font-size: calc(4px + 1vh);
      overflow: visible;
      padding-top: 1vh;
      width: 100%;
      height: 5vh;
    }

    .remainingDaysOf {
      display: inline;
      font-weight: 500;
      text-align: left;
      font-size: calc(4px + 1vh);
      overflow: visible;
      width: 100%;
      height: 5vh;
    }

    .ico {
      margin-right: 15px;
      font-size: calc(4px + 0.4vw);
    }

    .available-time-off {
      height: 5vh;
      border-bottom: 2px solid #f0f0f0;
    }

    .dayOffElement {
      display: flex;
      align-items: center;
      color: rgb(31, 30, 30);
      border-radius: 0px 8px 8px 8px;
      -moz-border-radius: 0px 8px 8px 8px;
      -webkit-border-radius: 0px 8px 8px 8px;
      margin: 0;
      padding: 0;
      text-align: justify;
      align-content: center;

      .imgCalendar {
        position: relative;
        display: inline-block;
        text-align: center;
      }

      .ico {
        margin-right: 10px;
        font-size: 20px;
      }

      .textIco {
        color: black;
        font-size: 11px;
        position: absolute;
        top: 19px;
        left: 10px;
        transform: translate(-50%, -50%);
      }

      .eventDescription {
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
      }

      .eventDescription:hover {
        font-weight: 700;
      }
    }
  }

  .tickler {
    margin-left: 82px;
    box-sizing: border-box;
    width: 100%;
    .timeline {
      border-radius: 10px;
      background-color: #fafafa;
      overflow: auto;
      padding: 0;
      margin: 0;
      max-height: calc(100vh - 10vh - 10vh - 29px - 20px - 30px);
      // Total height - topbar - Scheduler Header - header margin bottom - tolerancy - pad top

      h3 {
        padding: 1%;
        color: #171f5e;
        border-bottom: 0.1rem solid #b0b0b0;
        font-size: 1.5vw;
        height: 5%;
        margin: 0 auto;
      }

      .first-and-last-tickler {
        height: 30px;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 25px;
        font-size: 14px;
        line-height: 14px;
        font-family: "Roboto-light";

        span {
          color: #16448a;
          text-decoration: underline;
        }

        span:hover {
          cursor: pointer;
        }
      }

      .first-tickler {
        border-bottom: 1px solid #b0b0b0;
      }
    }

    .day-card {
      padding: 19px 35px 21px 30px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      border-bottom: 1px solid #b0b0b0;

      .day-header {
        padding-bottom: 8px;
        display: flex;
        font-size: 16px;
        .day-title {
          width: 50%;
          font-weight: 900;
        }
        .day-total {
          width: 50%;
          text-align: right;
          color: #595959;
          font-family: "Roboto-regular";
        }
      }
      .day-alert {
        width: 100%;
        font-size: 14px;
        padding-bottom: 8px;

        .holiday-move-btn {
          background-color: transparent;
          border: none;
          color: #16448a;
          text-decoration: underline;
        }
        .modal-dialog,
        .modal-dialog-centered {
          max-width: 616px !important;
        }

        .dayOffModal {
          .modal-backdrop.show {
            opacity: 0.2;
          }
        }
      }

      .work-card {
        width: 100%;
        font-size: 14px;
        padding: 10px 0;
        border-left: 4px solid #5ac775;
      }
      .work-card-task {
        display: flex;
        width: 100%;
        padding-left: 20px;
        font-family: "Roboto-regular";
      }
      .work-card-description {
        padding-top: 4px;
        padding-left: 20px;
        width: 100%;
        display: flex;
        font-family: "Roboto-bold";
      }
      .left {
        width: 50%;
      }
      .right {
        float: inline-end;
        text-align: right;
      }
      .rightdescription {
        width: 50%;
        text-align: right;
        color: #595959;
        font-family: "Roboto-light";
        font-size: 14px;
      }
    }

    .missing-hours-day {
      background-color: rgba(239, 147, 37, 0.15);

      .work-card {
        border-left: 4px solid #ef9325;
      }
    }

    .red-card {
      background-color: #ff0101;
      background-color: rgba(255, 1, 1, 0.15);
      font-size: 14px;
    }
  }
}

.timesheetModal > .modal-dialog {
  max-width: 616px !important;
}

.timesheetModal {
  .modal-content {
    //padding-left: 20px;
    //width: 616px;
    width: 100%;
    border: none;
    box-shadow: #d6d6d6 0 2px 6px;
    font-family: 'Roboto-Regular';

    .modal-body {
      padding: 0;
    }

    .dateAndTimeframesText,
    .timesheetModal label {
      margin-bottom: 5px;
      font-weight: 500;
    }

    .timesheetModalBody {
      // padding: 1rem !important;
      // margin: 0 1rem !important;
      padding: 1rem 2.5rem;
      //padding-bottom: 1rem;
      font-weight: 600;
      font-size: 15px;
    }

    .modal-footer {
      border-top: 0;
      padding: 0 2.5rem 2.5rem 2.5rem;
    }

    #timeOffLabel {
      margin-left: 206px;
    }

    .dayOffModalBody {
      padding: 1rem !important;
      margin: 0 1rem !important;
      justify-content: space-between;
    }

    #dayOffTitle {
      margin-left: 32px;
    }

    .modalHeader {
      //padding: 2rem 2rem;
      // font-family: "Roboto";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 23px;
      // line-height: 99.19%;
      // letter-spacing: 0.0015em;
      // color: #171f5e;
      color: #171f5e;
      font-weight: 700;
      font-size: 21px;
      padding: 2.5rem 1.5rem 1rem 2.5rem;
    }

    .modalTitle {
      color: #171f5e;
      font-weight: 700;
      font-size: 21px;
      padding-top: 1.5rem;
    }

    .close-icon {
      border: none;
      background-color: transparent;
    }

    .btn-close {
      font-size: 16px;
    }

    .stackModal {
      justify-content: space-between;
    }

    #typeSelect {
      padding: 5px 5px 5px 20px;
      width: 253px;
      height: 39px;
      background-color: #fafafa;
    }

    .timesheetSelect {
      background-color: #fafafa !important;
      color: $black !important;
      border: 1px solid #cccbcb !important;
      padding: 5px 5px 5px 20px !important;
      // margin: 2px auto !important;
      border-radius: 2px !important;
      width: 253px !important;
      height: 39px;

      option {
        font-size: 14px;
      }
    }

    .timesheetSelect[name="context"] {
      background-color: #fafafa !important;
      color: $black !important;
      border: 1px solid #cccbcb !important;
      padding: 5px 5px 5px 5px !important;
      margin: 2px auto !important;
      border-radius: 2px !important;
      width: 203px !important;

      option {
        font-size: 14px;
      }
    }

    .timesheetSelect[name="context1"] {
      background-color: #fafafa !important;
      color: $black !important;
      border: 1px solid #cccbcb !important;
      padding: 5px 5px 5px 5px !important;
      margin: 2px auto !important;
      border-radius: 2px !important;
      width: 253px !important;

      option {
        font-size: 14px;
      }
    }

    #feelingSelect {
      color: #747474;
    }

    .timesheetTotalDay {
      text-align: right;
      color: #595959;
      font-family: "Roboto-regular";
      font-size: 12px;
      padding-right: 25px;
    }

    #datePickerDiv.col-4 {
      padding-right: 0px;
      width: 180px;
    }

    #startTimeDiv {
      padding-left: 0px;
    }

    .timeframesField {
      padding-left: 13px;
      font-size: 12px !important;
      width: 135px;
      height: 39px;
      border: 1px solid #ced4da;
      border-radius: 5px;
    }

    .error {
      border: 1px solid red;
    }

    // .timeRangeInput {
    //   input[type="time"]::-webkit-calendar-picker-indicator {
    //     display: none;
    //   }
    //   .css-ittuaa-MuiInputAdornment-root {
    //     height: 17px;
    //     margin-right: 5px;
    //   }
    // }

    #dateField {
      padding: 5px;
    }

    .labelTimeframes {
      padding: 0 10px 10px 0;
      font-size: 12px;
    }

    .labelTimeframesDayOff {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 12px !important;
      padding-bottom: 5px;
      padding-top: 10px;
    }

    .tagsLabelModalDayOff {
      margin-top: 10px;
    }

    select[name="context"] {
      width: 200px;
      height: 39px;
      padding: 0px 5px 0px 20px;
    }

    #titleHoliday {
      padding: 5px 5px 5px 20px;
    }

    select[name="context1"] {
      width: 203px;
      height: 39px;
      padding: 0px 5px 0px 20px;
      background-color: #FAFAFA;
    }

    select[name="contextHoliday"] {
      width: 253px;
      height: 39px;
      padding: 0px 5px 0px 20px;
      background-color: #FAFAFA;
      margin-top: 2px;
    }

    #titleHoliday {
      padding: 0px 5px 0px 20px;
      height: 39px;
    }

    #description {
      height: 106px;
      resize: none;
      padding: 5px 5px 0px 20px;
    }

    #descriptionField {
      resize: none;
      padding: 0.5em 20px;
    }

    #noteField {
      height: 106px;
      resize: none;
      padding: 0.5em 20px;
    }

    #noteField::placeholder {
      line-height: 2rem;
    }

    .validity {
      color: #dc3545;
      text-align: right;
    }

    .submitButton {
      background-color: #5ac775 !important;
      border-color: #5ac775 !important;
    }

    .closeButton {
      background-color: #fafafa !important;
      border-color: #ff0101 !important;
      color: #ff0101 !important;
    }

    .checkBoxAndComponentOnModal {
      display: inline-flex;
      align-items: center;
      height: 39px;

      .picker {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 6px;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .eventModalCheckbox {
      font-size: 12px;
      width: 100px;

      #txt {
        display: flex;
        align-items: center;

        #cb1,
        #cb2 {
          //accent-color: #38B872;
          background-color: #38B872;
        }
      }

      .text-checkbox {
        margin: 0 10px;
      }
    }

    .react-datepicker__input-container input {
      font-size: 12px;
      height: 39px;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      padding: 0.375rem 1.25rem;
      width: 100%;
    }

    .react-datepicker__input-container button {
      background-color: #fff;
      font-size: 12px;
      width: 135px;
      height: 39px;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }

    .react-datepicker__input-container div {
      cursor:pointer;
      text-align: center;
      background-color: #fff;
      font-size: 12px;
      width: 135px;
      height: 39px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      span {
        position: relative;
        top: 25%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      svg {
        position: relative;
        margin-left: 20%;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }

    .hrModal {
      color: #F0F0F0;
      opacity: initial;
      height: 2px;
      margin: 0;
    }

    // .dayOffDatePicker input {
    //   //margin-left: -20px;
    // }

    .submitButton .btn .btn-primary {
      background-color: #5ac775;
    }

    .smallMessage {
      padding-top: 23px;
      font-size: 12px;
      display: flex;
      align-items: center;
    }

    #dateDayOff.col-4 {
      padding: 0 0 0 20px;
    }

    #dateDayOffLabel.col-2 {
      padding-right: 0px;
    }
  }
}

// #dateDayOff.col-4 {
//   padding: 0px;
//   padding-left: 20px;
// }

// #dateDayOffLabel.col-2 {
//   padding-right: 0px;
// }

.version {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #f8f7f7;
  width: 136px;
  height: 45px;
}
.versionText {
  margin-top: 15px;
  font-family: "Inter";
  text-align: center;
  padding: 2px;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #cfcfcf;
}

.eventModalCheckbox #txt {
  margin-left: 5px;
  margin-right: 5px;
}

.date-container {
  margin-left: 20%;
}

.fa-calendar {
  margin-left: 4px;
}

.hrInfo{
  margin: 5px;
}

.validity-tickler {
  color: #dc3545;
  text-align: right;
  font-size: x-small;
}
// .picker {
//   background-color: #fafafa !important;
//   color: $black !important;
//   // border: 1px solid !important;
//   border-color: #cccbcb !important;
//   padding: 5px 5px 5px 0 !important;
//   margin: 2px auto !important;
//   border-radius: 2px !important;
//   width: 100% !important;
//   font-size: small;
// }

@media only screen and (min-width: 1441px) {

}

@media only screen and (max-width: 1536px) {
  .scheduler-layout {
    margin-left: 55px !important;
  }

  .styleDiv1 {
    display: flex;
    align-items: baseline;
    position: relative;
    right: 27px !important;
    width: 286px !important;
  }
}

@media only screen and (max-width: 850px){
  .scheduler-layout{
    padding: 15px!important;
  }
  .scheduler{
    display: grid;
  }

}