.tags-input-container {
    border: 1px solid #ced4da;
    padding: 0.375em 20px;
    border-radius: 0.25em;
    width: 100%;
    font-size: 0.7em;
    display: block;
    align-items: center;
    flex-wrap: wrap;
    appearance: none;
    color: #212529;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  
  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .3em .75em;
    border-radius: 20px;
  }
  
  .tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
  }
  
  .tag-input {
    width: 100%;
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
  }