@import "src/assets/scss/core";
.tutorialContainer{
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.profileContainer {
  .profilePictureContainer {
    :hover {
      cursor: pointer;
    }
  }
  .profilePicture {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    
  }
  hr{
    margin: 0;
  }
}

.profileIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-right: 11px;
}
.settingsIcon {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-right: 11px;
}
.tutorialIcon {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-right: 11px;
}
.logoutIcon {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-right: 11px;
}

.drop {
  position: relative;
  left: -430%;
  float: right;
}
