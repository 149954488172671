.player-float{
  z-index: 1900;
  .player-menu{
    background-color: #e9e9e9;
    top: 70px;
    position: absolute;
    z-index: 1600;
    width: 172px;
    font-size: 14px;
  }
  .float-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background-color: #fff;
    box-shadow: 5px 4px 15px -10px #0000008f;
    border-radius: 4px;
    color: gray;
    opacity: none;
    .btn-player{
      background-color: transparent;
      border: 0px;
    }
  }
  p{
    margin: 0;
  }
  .opened{
    display: block;
  } 
  .closed{
    display: none;
  }
  .expansive-menu{
    display: flex;
    flex-direction: column;
  }
}

