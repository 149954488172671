.floatMenu {
  position: relative;

  .menu {
    box-shadow: 5px 4px 15px -10px #0000008f;
    position: absolute;
    padding: 10px;
    background-color: #fff;
    border-radius: 9px;
    min-width: 160px;
    z-index: 1900;
    top: -6px;
    // left: 100%

    .optionsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      color: #000000a3 !important;
      padding: 8px;
      text-align: start;
      font-size: 13px;
      background-color: transparent;
      width: 142px !important;
      border: none;
      justify-content: flex-start;
    }

    button:hover {
      background-color: #ecececa4;
    }

    #disabledButton{
      color:darkgrey !important;
      background-color: transparent !important;
      cursor: default !important;
    }

    .divider {
      background-color: #d6c5c5e3;
      height: 1px;
      width: 100%;
    }
  }

  .opened {
    display: block;
  }

  .closed {
    display: none;
  }

  .bottomLeft {
    right: 100%;
  }

  .bottomRight {
    left: 100%;
  }

  .topRight {
    bottom: 100%;
    left: 100%;
  }
}
