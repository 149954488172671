.am {
  position: absolute;
  /* background-color: blue; */
  background-image: url("../../../images/moon.png");
  border-radius: 50%;
}

.pm {
  position: absolute;
  /* background-color: orange; */
  background-image: url("../../../images/sun.jpg");
  border-radius: 50%;
}

.witheBorder {
  position: absolute;
  border-radius: 50%;
}

.progressCircle {
  background: green;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  z-index: -3;
  border-radius: 100%;
}

.circleContainer {
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
