@import "src/assets/scss/core";

.sidebarNav {
  margin-top: 32px;
  padding-left: 37%;

  .sidebarNavItems {
    padding: 0;
    height: 100%;

    .sidebarNavItem {
      list-style: none;
      height: 52px;
      padding-top: 8px;


      .sidebarNavIcon {
        height: 100%;
        color: $icon-color;
      }
      :hover {
        color: $bts-logo-font-color-2;
      }

      .active {
        color: $bts-logo-font-color-2;
      }
    }
  }
  .box-container {
    color: $icon-color;
    margin: 59vh 0 0 0;

  }
}
@media only screen and (min-width: 1537px) and (max-width: 1919px){
  .sidebarNav {
    .box-container {
      margin-top: 520px;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1536px){
  .sidebarNav {
    .box-container {
      margin-top: 320px;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 850px){
  .sidebarNav {
    background-color: rgb(255, 255, 255);
    height: 60px;
    margin-top: 0px;
    padding-left: 0px;

    .sidebarNavItems{
      padding: 17px;
      margin: 0 0 0 20px !important;
      text-align: center;
    }

    .sidebarNavItem{
      padding: 0 20px 0 20px !important;
      float: left;
    }

    .box-container {
      margin: 0px 0 0 0 !important;
    }
  }
}

