@import "src/assets/scss/core";

/*min-width and max-width are provisory*/
.dropdown-container-m0 {
  position: absolute;
  min-width: 141px;
  max-width: 600px;
  padding: 15px 10px;
  background: $white;
  color: $grey;
  font-size: $body-size;
  line-height: 2em;
  border-radius: $border-radius;
  box-shadow: $shadow-dropdown;
  transition: 0.3s ease-in-out all;
  margin-top: 10px;
  left: 5px;
  cursor: default;
  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  a {
    display: block;
    text-decoration: none;
    color: $grey;
  }
}

@media only screen and (max-width: 850px) and (max-height: 1500px){
  .dropdown-container-m0 {

  }
}
