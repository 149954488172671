@import "src/assets/scss/core";
.main-container {
  display: flex;
  flex-direction: column;
  color: $bts-logo-font-color-1;
  background-color: $bg-color-1;
  padding: 30px;
  overflow: auto;
  .header {
    display: flex;
    flex-direction: row;
    padding: 30px;
    border-bottom: 2px solid $another-grey;
    img {
      height: 84.66px;
      width: 84.31px;
      top: 50px;
      margin-left: 20px;
    }
  }
  .container1,
  .container2 {
    margin: 30px;
    padding: 5px 25px 25px 25px;
    border-radius: 5px;

    .titles1,
    .titles2 {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: baseline;

      .circle-n {
        width: 50px;
        height: 50px;
        background-color: rgba(23, 31, 94, 0.2);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 23px;

        min-width: 50px;
        .text {
          font-size: $heading-size-11 !important;
          font-weight: 700;
          margin: 0;
        }
      }
      .subtitle {
        font-size: $heading-size-11 !important;
        font-weight: 700;
      }
    }

    .cards {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      font-size: 16px;
      margin-left: 6px;
      .card1 {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin: 30px 5%;
      }
      .main {
        display: flex;
        flex-direction: row;
        .card2 {
          display: flex;
          flex-direction: column;
          margin: 20px 20px 17px 30px;
          .label {
            margin-bottom: 15px;
            margin-top: 15px;
          }
          .content {
            width: 100%;
            height: 100%;
            max-height: 228px;
            overflow: auto;
            border: 1px solid $select-border;
            border-radius: 3px;
          }
          .terms {
            width: 100%;
            height: 35%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            .check {
              display: flex;
              align-items: center;
              padding-bottom: 5px;
              .checkbox {
                margin-left: 10px;
                transform: scale(1.5);
              }
            }
            .btn-container1 {
              display: flex;
              align-items: flex-end;
              width: 150px;
              align-self: bottom;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $maxMobilePhone-width) {
  .main-container {
    display: flex;
    flex-direction: column;
    color: $bts-logo-font-color-1;
    padding: 0px;
    padding-top: 15px;

    .container1,
    .container2 {
      background-color: $bg-color-1;
      margin: 15px;
      padding-top: 15px;
      // padding: 30px;
      box-shadow: $shadow;
      border-radius: 5px;

      .cards {
        display: flex;
        flex-direction: column;
        .card1 {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin: 20px 40px 10px 10px;
        }
        .main {
          display: flex;
          flex-direction: row;
          .card2 {
            display: flex;
            flex-direction: column;
            // margin: 20px 20px 10px 30px;
            .content {
              width: 100%;
              border: 1px solid black;
              max-height: 300px;
            }
          }
        }
        .btn-container1 {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          margin: 10px;
          align-self: center;
        }
      }
    }
  }
}
