@import "/src/assets/scss/variables";

.top-tickler-timesheets {
  height: 292px;
  overflow: scroll;
  margin-top: 20px;

  h3 {
    padding: 1%;
    color: #171f5e;
    border-bottom: 0.1rem solid #b0b0b0;
    font-size: 1.5vw;
    height: 5%;
  }

  .timeline {
    padding: 5%;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    background-color: #fafafa;
    border-radius: 10px;
    padding-top: 0;
    overflow: scroll;
  }

  .day-card {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #b0b0b0;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 10px;
    line-height: 14px;

    .day-header {
      padding-bottom: 10px;
      display: flex;
      font-weight: bold;
      font-size: 12px;
      .day-title {
        width: 50%;
      }
      .day-total {
        width: 50%;
        text-align: right;
        color: #9c9c9c;
        font-weight: normal;
      }
    }

    .work-card {
      width: 100%;
      border-left: 4px solid #5ac775;
    }
    .work-card-task {
      display: flex;
      width: 100%;
      padding-left: 5%;
      div {
        width: 75%;
        padding-bottom: 2%;
      }
    }
    .work-card-description {
      padding-left: 5%;
      width: 100%;
      display: flex;
      padding-bottom: 2%;
    }
    .left {
      width: 30%;
    }
    .right {
      width: 100%;
      text-align: right;
    }
    .rightdescription {
      width: 100%;
      text-align: right;
      color: #9c9c9c;
    }
  }
  .red-alerts {
    margin-bottom: 30px;
  }
  .red-card {
    background-color: #ff0101;
    background-color: rgba(255, 1, 1, 0.15);
    font-size: 12px;
  }
  .missing-hours-day {
    background-color: rgba(239, 147, 37, 0.15);

    .work-card {
      border-left-color: #ef9325;
    }
  }
}
.record {
  position: fixed;
  width: 22px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
// topbar
#dropdown {
  :hover {
    cursor: pointer;
  }

  .dropClock {
    position: relative;
    right: 1600%;
    float: right;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ticklerContainer {
  .form-control {
    appearance: auto;
    margin-bottom: 10px;
    border-radius: 0;
    font-size: 12px;
    font-family: "Roboto-regular";
    color: #8a8a8a;
  }

  .startButton {
    margin-top: -12px;
    background-color: white !important;
    border-color: white !important;
    color: turquoise !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 22px;
  }

  input[name="task"] {
    color: black;
  }

  select[name="project"] {
    color: black;
  }
}

.dropdown-container {
  width: 363px;
  color: black;
  position: fixed;
  right: 85px;

  .top-tickler-title {
    font-size: 18px;
    font-family: "Roboto-bold";
    color: #171f5e;
    margin-top: 10px;
  }

  .top-tickler-form {
    margin-bottom: 20px;

    .form-control {
      font-size: 11px;
      padding: 2px;
      border-radius: 0;
      color: #8a8a8a !important;
      height: 27px;
      width: 269px;
    }

    .form-control.is-invalid {
      background-image: url(../../../components/Icons/exclamation-mark.png);
      background-position: 96% 50%;
      background-size: 5%;
    }

    .form-select {
      font-size: 11px;
      padding: 2px;
      border-radius: 0;
      color: #8a8a8a !important;
      height: 27px;
      width: 127px;
    }

    .top-tickler-input-2 {
      width: 127px;
      height: 27px;
    }

    .form-select.top-tickler-input-2 {
      padding-right: 15px;
      background-position: 97% 50%;
      cursor: pointer;
    }

    .form-select.top-tickler-input-2.is-invalid {
      background-image: url(../../../components/Icons/exclamation-mark.png);
      background-position: 96% 50%;
      background-size: 10%;
      padding-right: 12px;
    }

    .top-tickler-input-3 {
      width: 127px;
      height: 27px;
      margin-left: 6px;
    }

    .form-select.top-tickler-input-3 {
      padding-right: 12px;
      background-position: 95% 50%;
      cursor: pointer;
    }

    .form-select.top-tickler-input-3.is-invalid {
      background-image: url(../../../components/Icons/exclamation-mark.png);
      background-position: 96% 50%;
      background-size: 10%;
      padding-right: 12px;
    }
  }
}

.rti--container {
  --rti-bg: #fff;
  --rti-border: #000000;
  --rti-main: #3182ce;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem; /* spacing */
  --rti-tag: #edf2f7;
  --rti-tag-remove: #e53e3e;
}

.textArea {
  width: 100%;
  height: 100px;
  border: 1px solid #8a8a8a;
  padding: 5px 10px;
  font-size: 12px;
  resize: none;
}

#finishTask {
  float: right;
  background-color: #5ac775;
  border-color: #5ac775;
  font-family: "Roboto-medium";
  font-size: 9px !important;
  width: 60px;
  height: 21px;
  padding-top: 3px !important;
  margin-top: 10px;
}

.btn-danger {
  background-color: #fafafa !important;
  color: #ff0101 !important;
  border-color: #ff0101 !important;
  font-family: "Roboto-medium";
  font-size: 9px !important;
  width: 60px;
  height: 21px;
  padding-top: 3px !important;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

.taskTimer {
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
  color: #171f5e;
  font-size: 22px;
  font-family: "Roboto-bold";

  .icon {
    position: fixed;
    right: 315px;
    top: 92.5px;
  }
}

.warningTitle {
  white-space: nowrap;
  overflow: auto;
  color: #FF0101;
  letter-spacing: 0.01em;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: initial;
}

.warningParagraph {
  color: #FF0101;
  letter-spacing: 0.01em;
  line-height: 99.19%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  padding-bottom: 20px;
}

/* Tickler buttons */

.edit-task-container {
  padding: 0;
  float: right;
  margin-right: 0px !important;
  margin: auto;
}

.btn-container {
  float: right;
}

.btn-container > button {
  background-color: transparent;
  border: none;
  color: #1263dd;
  margin-left: 10px;
  padding: 0;
  float: right;
}

.exceededHoursWarning {
  margin-top: 6px;
}

#scheduler_loger {
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  float: right;
  color: #16448A;
  text-decoration-line: underline;
  letter-spacing: 0.04em;
}

/*
Mobile Resolution
*/
@media only screen and (max-width: $maxMobile-width) {
  .dropdown-container {
    width: 100%;
    color: black;
    position: fixed;
    right: 0;

    .top-tickler-form {
      .top-tickler-input-2 {
        width: 100%;
      }
      .startButton {
        width: 10%;
        position: relative;
        left: 45%;
      }
    }
  }
}
