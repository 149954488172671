@import "src/assets/scss/core";

.cover {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  position: absolute;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  color: #171f5e;

  .guideInformation {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: fit-content;
    font-family: "Roboto";
    font-style: normal;
    line-height: 99.19%;
    letter-spacing: 0.04em;
    background: #ffffff;
    padding: 1%;
    border-radius: 15px;
    position: absolute;
    border: 2px solid grey;
    margin-left: 10px;

    .title {
      font-weight: bold;

    }

    .subtitle {
      font-weight: 400;
      font-size: 24px;
      letter-spacing: 0.04em;
      display: flex;
      margin-bottom: 30px;
    }

    .description {
      display: inline;
      position: relative;
      font-size: 20px;
      color: #000000;
      letter-spacing: 0.04em;
      line-height: 124.2%;
      white-space: pre-wrap;
    }


    #btnContainer {
      display: flex;
      justify-content: flex-end;

      .continueButton {
        width: 126px;
        height: 40px;
        padding: 10px;
        background: transparent;
        border: 1px solid #171f5e;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        color: #171f5e;
        transition: all 0.5s;
      }

      .continueButton:hover {
        background: #171f5e;
        color: white;
      }

      .skipButton {
        width: 126px;
        height: 40px;
        padding: 10px;
        background: transparent;
        border: 1px solid #1263DD;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        color: #1263DD;
        transition: all 0.5s;
        margin-right: 20px;
      }

      .skipButton:hover {
        background: #1263DD;
        color: white;
      }

      .completedButton {
        width: 190px;
        max-width: 200px;
      }
    }
  }

  //Dont detele this , this can be useful if later they want to implement an arrow pointing to the element
  //   .guideInformation:after {
  //     position: absolute;
  //     width: 40px;
  //     height: 40px;
  //     border-top: 2px solid #171f5e;
  //     border-right: 0px solid #171f5e;
  //     border-bottom: 0px solid #171f5e;
  //     border-left: 2px solid #171f5e;
  //     bottom: 100%;
  //     left: 50%;
  //     margin-left: -290px;
  //     content: "";
  //     z-index: 90;
  //     transform: rotate(7deg);
  //     margin-bottom: -35px;
  //   }
  // .right {
  //   text-align: right !important;
  // }

  // .center {
  //   text-align: left;
  // }
}

// Dont delete it , it might be useful if we need to set other configurations for tablet resolutions
// @media only screen and (max-width: $maxMobile-width) {
//   .cover {
//     .guideInformation {
//       width: 30%;
//     }
//   }
// }
@media only screen and (max-width: 850px){
  .guideInformation{
    width: 85% !important;
  }
}