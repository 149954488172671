@import "src/assets/scss/core";

* {
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
}

.top {
  width: 100%;
  flex: 1;
  margin-top: 1%;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.bottom {
  width: 100%;
  flex: 9;
}

.bTop {
  display: grid;
  grid-template-columns: 1.5fr 1fr repeat(3, 0fr);
  grid-row-gap: 1rem;
  padding-bottom: 2rem;
}

.whatIsGoingOn {
  grid-area: 1 / 1 / 2 / 2;
}

.whatIsNext {
  grid-area: 1 / 2 / 2 / 3;
}

.bBottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 41px;
  grid-row-gap: 0px;
}
.gmanagers {
  grid-area: 1 / 1 / 2 / 2;
}
.gTitans {
  grid-area: 1 / 2 / 2 / 3;
}
.version {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #F8F7F7;
  width: 136px;
  height: 45px;
}
.versionText{
  margin-top: 15px;
  font-family: 'Inter';
  text-align: center;
  padding: 2px;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #CFCFCF;
}

@media only screen and (max-width: $maxMobile-width) {
  .bottom {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    padding: 15px;
    
    .bBottom {
      display: flex;
      flex-direction: column;
      .gmanagers {
        margin-bottom: 15px;
      }
    }
    .bTop {
      display: flex;
      flex-direction: column;
    }
  }
  .top {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

// .card {
//   min-width: 180px;
//   width: 70%;
//   margin-left: 0;
//   margin-bottom: 2.5%;
//   box-shadow: 0px 0px 22px rgb(0 0 0 / 4%);
//   border-radius: 9px;
// }
