@import "src/assets/scss/core";

.container {
  background-color: $bg-color-1;
  max-width: 3000px;
  .video {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    .video_container {
      width: 310px;
      height: 190px;
      margin-top: 45px;
      min-width: 200px;
      .box_text {
        text-align: center;
        margin-top: 30px;
        a {
          color: $bts-logo-font-color-1;
          font-size: 18px;
        }
      }
      .ceo,
      .onboarding,
      .wiki {
        width: 100%;
        height: 100%;
        background-color: $container-grey;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .linkedin {
    height: 100px;
    border: 1px solid black;
  }
  .select {
    display: flex;
    .card1,
    .card2,
    .card3,
    .car4 {
      width: 25%;
      display: flex;
      flex-direction: column;
      margin: 10px 10px 10px 10px;
    }
  }
}
@media only screen and (max-width: $maxLaptop-width) {
  .container {
    .video {
      .video_container {
      }
    }
  }
}
@media only screen and (max-width: $maxMobile-width) {
  .container {
    .video {
      .video_container {
        margin-left: 5%;
      }
    }
  }
}

@media only screen and (max-width: $maxMobilePhone-width) {
  .container {
    width: 100%;
    .video {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      align-items: center;

      .video_container {
        max-width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}
