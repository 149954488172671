@import "../../assets/scss/variables";

.time-tracking-table {
  padding: 20px 0 0 3rem;
  .tt-body {
    padding: 0;
  }
  .tt-header {
    display: flex;
    justify-content: space-between;
    .title {
      font-family: "Roboto-Medium", sans-serif;
      font-size: 1.25rem;
      color: #171f5e;
    }
    .periods {
      display: flex;
      justify-content: flex-end;

      p {
        padding-left: 12px;
        font-size: 14px;
        color: black;
        padding-bottom: 0;
      }
      .this-week{
        .inside{
          width:40%;
          height: 2px;
          background-color: #16448a;
          text-align: center;
          margin-left: 17px;
      }
      }
    }
  }
  .table-players {
    td {
      padding: 2px 10px;
      text-align: center;
    }
    .from-to {
      padding: 2px 10px;
      font-size: 14px;
      line-height: 14px;
      color: black;
      text-align: start;
    }
    .player-info{
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-user {
       width: 49px;
       height: 49px;
       object-fit: cover;
       margin: 10px;
       border-radius: 50%;
       cursor: pointer;
     }
   }
  }
  .totals-expected {
    background-color: #eeeeee;
    .tot-exp {
      text-align: start;
    }
    td {
      text-align: center;
      line-height: 24px;
      p {
        font-size: 14px;
        color: black;
      }
    }
  }
  .hours{
    font-size: 14px;
    color: black;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
     :nth-child(4){
      color: red;
    }
    :nth-child(5){
      color: orange;
    }
    td{
    padding: 3px 0 0 12px;
    }
    .date{
      text-align: start;
    }
  }
  .full-pg{
    padding-top: 10px;
    color: #16448a;
    font-weight: 300;
    font-family: "Roboto-Light";
    font-size: 12px;
    letter-spacing: 0.48px;
    u {
      cursor: pointer;
    }
  }
}
