@import "src/assets/scss/core";
.container {
  display: flex;
  align-content: center;
  align-items: center;
}

.welcome_primary {
  color: $welcome-message-primary;
  padding-top: $padding-top-home;
  font-family: $roboto-regular;
  font-size: $heading-size-11;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-weight: 800;
  padding-left: 2.3rem;
  margin-top: 15px;
  margin-left: 5px;
}

.welcome_secondary {
  color: #000;
  font-family: $roboto-light;
  font-size: $heading-size-2;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  padding-left: 2.3rem;
  font-weight: 800;
  margin-left: 5px;
}

.title {
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: 0;
  }

  .motivational_phrase {
    padding-top: 0;
    padding-left: 0;
  }

  .welcome_primary {
    font-size: 1.6rem;
    justify-content: center;
  }

  .welcome_secondary {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  .icon_title {
    justify-content: center;
    padding-top: 1.5rem;
    margin-left: 4px;
  }
}
