@import "../../assets/scss/variables";
.tech-stack-main {
  padding: 0 1.563rem 1.563rem;

  h2 {
    font-family: $roboto-medium;
    font-weight: 500;
    font-size: 1.25rem;
    color: #171f5e;
    margin: 0 0 1.25rem 0;
  }

  .tech-badge {
    display: inline-block;
    font-family: Roboto-Light;
    padding-bottom: 0.7rem;

    .badge {
      padding: 0.625rem 0.938rem;
      margin-right: 0.625rem;
      font-weight: normal;
      font-size: 13px;
    }
    .languages {
      background-color: #535cb9 !important;
    }
    .tools {
      background-color: #652cb3 !important;
    }
    .db-engines {
      background-color: #0ca789 !important;
    }
  }
}
