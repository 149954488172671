@import "../../assets/scss/variables";
@import "../../assets/scss/variables";

.flex-header {
  display: flex;
}

.title-container {
  align-items: flex-start;
  font-family: $roboto-medium;
  font-size: 24px;
}

.employee-list {
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat
    no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 4px 20px;
    background-position: 92%;
    height: 39px;
    width: 253px;
    border: 0.4px solid $select-border;
    border-radius: 3px;
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: "Roboto";
    line-height: 99.19%;
    letter-spacing: 0.0015rem;
  }
}

.role-access-title{
  margin-top:25px;
  margin-right: 10px;
  font-size: 35px;
}

.actual-role{
  display: flex;
  margin-top:25px;
}

//dibuja una linea vertical

.line-yellow{
  border-left: thick solid #faf200;
}
.line-green{
  border-left: thick solid #00b780;
}
.v-line{
  margin-top: 5px;
  height:15%;
  left: 50%;
}

.line-gray{
  border-left: thick solid darkgray;
}

.permissions-container .line-color:nth-child(3n+1) {
  border-left: thick solid #00b780;
}
.permissions-container .line-color:nth-child(3n+2) {
  border-left: thick solid #faf200;
}
.permissions-container .line-color:nth-child(3n+3) {
  border-left: thick solid #faf200;
}

.permissions-container .line-color:nth-child(3n+5) {
  border-left: thick solid #00b780;
}

.badge-location{
  display: flex;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-role{
  display: flex;
}


.badge-success {
  margin: 5px;
  background: green;
  height: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2.5px 12px 0 12px;
  display: inline-block;
  overflow:hidden;
  text-overflow: ellipsis;
}

.flex-header{
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.trash-icon {
  color: grey !important;
  font-size: 13px;
  background: white !important;
  border-radius: 5px;
  border: 0;
}

trash-icon:not(:disabled) {
  cursor: pointer;
}

.select-role-container{
  width: 100%;
  margin-left: 30px;
}

.employee-list {
  margin-left: 20px;
}

.location-section{
  display: flex;
  padding-left: 0px;
}

.info{
  font-size: 12px;
  margin-top: 20px;
  margin-left: 20px;
  width: 35%;
  display: flex;
  color: #9c9c9c;
}

.btn-add{
  height: 40px;
  margin-top: 14px;
  border-radius: 10px;
  background-color: #0a53be !important;
  padding: 2px 15px 2px 15px;
}

.btn-add:hover{
  background-color: #0d6efd !important;
}

.btn-container{
  display: flex;
}

.btn-cancel{
  height: 40px;
  width: 70px;
  margin: 30px 0 30px 0;
  border-color: #0a53be !important;
  color: #0a53be;
  border-radius: 5px;
  background-color: white !important;
  padding: 2px 15px 2px 15px;
}

.btn-cancel:hover{
  background-color: #0a53be !important;
  color: white;
}

.btn-save{
  height: 40px;
  width: 70px;
  margin: 30px 0 30px 0;
  border-color: #0a53be !important;
  color: white !important;
  border-radius: 5px;
  background-color: #0a53be !important;
  padding: 2px 15px 2px 15px;
}

.btn-save:hover{

  background-color: #0d6efd !important;
  color: white !important;
}

.btn-remove{
  height: 40px;
  width: 150px;
  border-radius: 5px;
  border-color: transparent;
  color:white !important;
  background-color: #E42B02 !important;
  padding: 2px 15px 2px 15px;
}

.btn-remove:hover{
  background-color: #FC5A37;
}

.btn-disabled{
  height: 40px;
  width: 150px;
  border-radius: 5px;
  border-color: transparent;
  color:white !important;
  background-color: dimgrey !important;
  padding: 2px 15px 2px 15px;
}

.btn-container{
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
}