@import "../../assets/scss/variables";

.projectOverview {
  padding-top: 20px;
  padding-bottom: 30px;

  h2 {
    display: flex;
    justify-items: flex-start;
    align-items: baseline;
    padding-bottom: 10px;
    font-family: $roboto-medium;
    font-size: $heading-size-2;
    color: $primary;
  }
  p {
    font-family: $roboto-regular;
    font-size: $heading-size-4;
    color: $black;
    line-height: 1.1;
  }
}

.edit {
  width: 16px;
  height: 16px;
  margin: 5px;
  align-items: center;

  button {
    background: transparent;
    border-color: transparent;
    padding-left: 2px;
    color: #171F5E;
  }
}

.overviewEdit {
  width: 90%;
  height: 106px;
  resize: none;
  padding: 0.5em 20px;
}

.buttons {
  position: relative;
  width: 90%;
}

.cancelButton {
  position: absolute;
  right: 83px;
  top: 0;
  font-family: $roboto-regular;
  font-size: 13px;
  width: 83px;
  height: 31px;
  border-radius: 5px;
  color: #ff0101;
  border-color: #ff0101;
  background-color: #ffffff;
  margin-right: 11px;
}

.confirmButton {
  position: absolute;
  right: 0;
  top: 0;
  font-family: $roboto-regular;
  font-size: 13px;
  width: 83px;
  height: 31px;
  border-radius: 5px;
  background-color: #5ac775;
  color: #ffffff;
  border-color: #5ac775;
}

.lineprojectOverview {
  border-bottom: solid 2px #f0f0f0;
  width: 77%;
  margin-left: 4px;
  margin-bottom: 3px;
}