@import "src/assets/scss/core";
.skill {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 16px;
  margin: 5px 0px;

  .active,
  .inactive {
    color: white;
    list-style: none;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
  .active {
    background-color: $skills-selected;
    font-weight: 400 !important;
  }
  .inactive {
    background-color: grey;
  }
}
