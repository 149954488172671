@import "../../assets/scss/variables";
.happening-next-main {
  padding: 0 1.563rem 1.563rem;

  h2 {
    font-family: $roboto-medium;
    font-weight: 500;
    font-size: 1.25rem;
    color: #171f5e;
    margin-bottom: 1.25rem;
  }
}

.hn-table {
  border-collapse: collapse;
  font-size: 14px;
  margin: 30px 15px;
td {
    text-align: left;
    padding: 7px;
  }

  
}
