.event-modal {
  width: 100%;
  border: none;
  box-shadow: #d6d6d6 0 2px 6px;
  font-family: 'Roboto-Regular';
  padding: 0;

  //timesheetModalBody
  .event-modal-body {
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-size: 15px;
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    border-top: 0;
    padding: 1rem 2.5rem 2.5rem 2.5rem;
  }

  .modal-header {
    padding: 1rem 1.5rem 1rem 2.5rem;
    box-shadow: #d6d6d6 0 1px 2px;
  }

  .modal-title {
    color: #171f5e;
    font-weight: 700;
    font-size: 21px;
    padding-top: 1.5rem;
  }

  .close-icon {
    border: none;
    background-color: transparent;
  }

  .checkBoxAndComponentOnModal {
    display: inline-flex;
    align-items: center;

    .picker {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 6px;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .eventModalCheckbox {
    font-size: 12px;

    #txt {
      display: flex;
      align-items: center;
      padding-left: 7px;

      #cb1,
      #cb2 {
        accent-color: #38B872;
      }
    }

    .text-checkbox {
      margin: 0 10px;
    }
  }

  .react-datepicker__input-container input {
    font-size: 12px;
    height: 39px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 0.375rem 1.25rem;
    width: 100%;
  }

  .react-datepicker__input-container button {
    background-color: #fff;
    font-size: 12px;
    width: 135px;
    height: 39px;
    border: 1px solid #ced4da;
    border-radius: 2px;
  }

  .react-datepicker__input-container div {
    cursor:pointer;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    width: 135px;
    height: 39px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    span {
      position: relative;
      top: 25%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    svg {
      position: relative;
      margin-left: 20%;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .hrModal {
    color: #F0F0F0;
    opacity: initial;
    height: 2px;
    margin: 0;
  }

  .descriptionField{
    padding: 0px 2.5rem 1rem 2.5rem;

  }

  .toField{
    position: static;
  }

  .select-context-field {
    display: inline-block;
    margin-top: 3px;
  }

  .validity {
    padding-left: 2.5rem;
    div {
      padding-left: 2.5rem;
      text-align: left;
    }
  }
}