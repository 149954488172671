.allocation-container {
  width: 100%;
  padding: 10px;
  font-family: 'Roboto-Regular';

  .get-table-error {
    color: red;
  }

  .table-title {
    padding-bottom: 10px;
    color: #171f5e;
    font-weight: 600;
  }

  .allocation-table {
    display: flex;
    flex-flow: column wrap;
    padding: 0;

    .allocation-header {
      display: flex;
      justify-content: flex-start;
      color: #6c757d;

      td {
        width: 20%;
      }

      .thrash-icon {
        display: flex;
        justify-content: flex-end;
      }
    }

    .allocation-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      padding: 8px 0 8px 0;

      td {
        width: 20%;
        font-size: 11px;
      }

      .img-project {
        height: 26px;
        margin-right: 8px;
      }

      .icons-container {
        display: flex;
        justify-content: flex-end;

        svg,
        label {
          margin-left: 10px;
        }
      }
    }
  }

  .allocation-footer {
    display: flex;
    padding-top: 20px;

    h6 {
      padding-left: 10px;
    }
  }
}