.add-player-modal {
  h4 {
    font-size: 23px !important;
    color: #171f5e !important;
    font-weight: 600 !important;
    margin-left: 1rem;
    margin-top: 1rem;
    font-family: "Roboto-Regular";
  }

  h5 {
    color: #171f5e !important;
    font-weight: 600 !important;
    font-family: "Roboto-Regular";
    font-size: 23px !important;
  }

  .addPlayerForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 0.8rem;
    padding: 1rem;

    .formField {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      margin-bottom: 1rem;
      label {
        font-family: "Roboto-Medium";
      }
    }
    .formField.allocation {
      label,
      div {
        display: none;
      }
    }

    .formikError {
      color: red;
    }

    .icon-outside-input {
      display: flex;
      column-gap: 10px;
      align-items: center;

      input {
        max-width: 40%;
      }
    }

    .inputWithIcon {
      display: flex;
      align-items: center;
      position: relative;
      svg {
        position: absolute;
        right: 15px;
        top: 11px;
      }
      .form-control {
        width: 100%;
      }
      label {
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      grid-template-columns: 1fr !important;
    }
  }

  .moreOptions {
    padding: 1rem;
    border-top: 1px solid #e7e5e5;

    .moreOptionsField {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }
  }
}
