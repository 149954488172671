@import "src/assets/scss/core";

$breakpoints: (
  sm: 550px,
  md: 768px,
  lg: 1024px,
);

@mixin media-up($breakpoint) {
  @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

.ManagersContainer {
  background: #ffffff;
  box-shadow: 0px 0px 22px rgb(0 0 0 / 4%);
  border-radius: 9px;
  width: 100%;
  height: 500px;
  overflow: hidden;
  float: right;

  .ManagersTitle {
    font-family: 'Roboto';
    font-style: normal;
    color: $heading-text-color;
    font-size: 18px;
    font-weight: 500;
    padding: 19.6px;
    margin: 0;
    box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.22);
    border-radius: 9px 9px 0px 0px;
    line-height: 99.19%;
    letter-spacing: 0.02rem;
  }

  .ManagersGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .ManagerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;

    span {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding: 2px 0;
      cursor: pointer;
    }

    .ManagerInfo {
      .Icons{
        display: flex;
      }
      h3 {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        line-height: 99.19%;
        letter-spacing: 0.02rem;
        color: #000000;
        margin-bottom: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h4 {
        font-family: 'Roboto';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 99.19%;
        letter-spacing: 0.02rem;
        margin: 0;
        height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 6px;
        margin-top: 6px;
        max-width: 200px;
      }
    }

    .ManagerPhoto {
      display: flex;
      width: 50px;
      justify-content: center;
      align-items: center;
      margin: 1rem 1rem 10px 1rem !important;
    }

    .ManagerPhoto figure {
      height: 50px;
      width: 50px;
    }

    .ManagerPhoto img {
      width: 100%;
      height: auto;
      border-radius: 2px;
    }

    @supports (object-fit: cover) {
      .ManagerPhoto img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    .ManagerInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 50%;
    }
  }

  @include media-up(sm) {
    .ManagersTitle,
    .ManagerInfo h3,
    .ManagerInfo h4 {
      font-size: 22px;
    }
  }
}
.titan {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-flow: row wrap;

  .ManagerContainer {
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: $maxMobilePhone-width) {
  .ManagerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    .ManagerInfo {
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
    }

    .titan {
      justify-content: center;
    }
  }
}

@media only screen and (min-width: $minstandardMonitor-width) {
  .ManagerContainer {
    .ManagerInfo {
      display: flex;
      text-align: start;
    }
  }
}
