@import "src/assets/scss/core";

.whatsNextContainer {
  width: 100%;
  height: 100%;
  .whatsNextTitle {
    font-family: $roboto-medium;
    font-size: 20px;
    margin: 20px 0px 0px 0px;

  }
  
}
