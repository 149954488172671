@import "src/assets/scss/core";
.container {
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    margin-top: 36px;
    display: flex;
    justify-content: space-between;

    .breadCrumb {
      height: 10%;
      display: flex;
      flex-direction: row;
      font-family: $roboto-bold;
      font-size: 26px;

      .global {
        display: flex;
        flex-direction: row;
        color: $bts-logo-color-2;

        .icon {
          padding-right: 5px;
          color: $bts-logo-color-2;
        }

        .newsLayout {
          color: $bts-logo-color-2;
        }
      }
    }
    button {
      color: white;
      font-size: 13px;
      background: $btn-happy;
      border-radius: 5px;
      border: 0;
      height: 40px;
      width: 120px;
    }
  }
}

.news {
  color: #6c757d;
}

.header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $another-grey;
  padding: 0 0 10px 0;

  .p1 {
    width: 58%;
    padding-left: 2px;

  }
  .p2 {
    width: 14%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .p3 {
    margin: 0;
  }
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #F8F7F7;
  width: 136px;
  height: 45px;
}
.versionText{
  margin-top: 15px;
  font-family: 'Inter';
  text-align: center;
  padding: 2px;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #CFCFCF;
}

@media screen and (max-width: $maxMobilePhone-width) {
  .top {
    margin-top: 40px;
  }
  .header {
    .p1 {
      margin-left: 10px;
    }
    .p2 {
      display: none;
    }
    .p3 {
      display: none;
    }
  }
}
