@import "../../assets/scss/variables";
.teamRoles {
  padding-top: 30px;
  padding-bottom: 30px;
  .users {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    padding-left: 1.1rem;
  }

  .user {
    display: flex;
    flex-flow: inherit;
    align-items: center;
  }

  .user-inactive {
    display: flex;
    flex-flow: inherit;
    align-items: center;
    img,
    svg,
    .info-user,
    .capital-letters {
      opacity: 30%;
    }
  }

  .name {
    font-weight: bold;
    color: black;
    font-family: "Roboto-Medium";
    margin-top: -4px;
  }

  .user-inactive .img-user {
    width: 49px;
    height: 49px;
    object-fit: cover;
    margin: 10px;
    border-radius: 50%;
    cursor: auto;
  }
  .user .img-user {
    width: 49px;
    height: 49px;
    object-fit: cover;
    margin: 10px;
    border-radius: 50%;
    cursor: auto;
  }
  .player-container {
    position: relative;
    svg {
      position: absolute;
      right: 9px;
      top: 4px;
    }
  }
  .capital-letters {
    min-width: 49px;
    min-height: 49px;
    object-fit: cover;
    margin: 10px;
    border-radius: 50%;
    background-color: #ccd1d1;
    text-align: center;
    cursor: pointer;
    .initials {
      text-align: center;
      margin-top: 12px;
      font-size: 18px;
      letter-spacing: 1px;
      color: rgb(82, 81, 81);
      font-family: "Roboto-Medium";
      line-height: 48px;
    }
  }
  .role {
    color: grey;
    font-family: "Roboto-Regular";
    font-size: 14px;
  }

  h2 {
    display: flex;
    justify-items: flex-start;
    align-items: baseline;
    padding-bottom: 10px;
    font-weight: 500;
    font-family: $roboto-medium;
    font-size: 1.25rem;
    color: #171f5e;
    svg:nth-child(n) {
      margin-bottom: 2px;
      margin-left: 10px;
      cursor: pointer;
    }
    .linePlayers {
      border-bottom: solid 2px #f0f0f0;
      width: 77%;
      margin-left: 4px;
      margin-bottom: 3px;
    }
  }
  .inactive-players {
    padding-bottom: 10px;
    color: #16448a;
    font-weight: 300;
    padding-left: 1.18rem;
    font-family: "Roboto-Light";
    font-size: 12px;
    letter-spacing: 0.48px;
    u {
      cursor: pointer;
    }
  }
}

.player-mood-project{
  position: absolute;
  right: 4px;
  top: 42px;
}