.action-button {
  background-color: #38b872;
  min-width: 85px;
  appearance: none;
  backface-visibility: hidden;
  border-radius: 5px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 5px 20px;
  margin: 0px 0px 0px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;

  &:disabled {
    opacity: 0.3;
  }
}

.action-button:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
  box-shadow: rgba(39, 174, 96, 0.2) 0 6px 12px;
}

.action-button:active {
  transform: translateY(2px);
  transition-duration: 0.35s;
}

.secondary-action-button {
  min-width: 85px !important;
  width: 36% !important;
  background-color: initial;
  border: 1px solid #FF0101 !important;
  border-radius: 5px;
  box-sizing: border-box;
  color: #FF0101 !important;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.4;
  overflow: hidden;
  outline: 0;
  padding: 5px 20px;
  margin: 0px 6px 0px 0px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
}

.secondary-action-button:hover,
.secondary-action-button:active {
  background: #FF0101 !important;
  color: #fff !important;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
  box-shadow: rgba(174, 39, 39, 0.15) 0 4px 9px;
}

.secondary-action-button:active {
  transform: translateY(2px);
  transition-duration: 0.35s;
}

.cancel-delete-button {
  background-color: #6e7881;
  min-width: 85px;
  appearance: none;
  backface-visibility: hidden;
  border-radius: 5px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 5px 20px;
  margin: 0px 0px 0px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;

  &:disabled {
    opacity: 0.3;
  }
}

.cancel-delete-button:hover {
  background-color: #444950;
  opacity: 1;
  transform: translateY(0);
  transition-duration: 0.35s;
  box-shadow: rgba(67, 64, 64, 0.2) 0 6px 12px;
}

.cancel-delete-button:active {
  transform: translateY(2px);
  transition-duration: 0.35s;
}