@import "src/assets/scss/core";

.motivational {
  font-style: italic;
  text-align: right;
}
.author {
  text-align: right;
}
@media only screen and (max-width: $maxMobile-width) {
  .containerMotivational {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .author {
    text-align: center;
  }
  .motivational {
    text-align: center;
    margin-top: 0px;
    padding-right: 15px;
  }
}
