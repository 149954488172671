@import "src/assets/scss/core";

.view_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 30px 30px;
  .google-btn {
    margin-top: 40px;
  }
  .google-btn span {
    font-weight: 800;
  }
  img {
    margin-bottom: 20px;
  }
  h3 {
    font-size: $heading-size-1;
    font-weight: bold;
    color: $welcome-message-primary;
    text-align: center;
  }
  label {
    color: $body-text-color-3;
    max-width: 350px;
    text-align: center;
    font-size: $heading-size-2;
  }
}
