@import "src/assets/scss/core";
.input {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  .element{
    z-index: 1;
  }
  .inputfiles {
    display: inline-block;
    outline: none;
    white-space: nowrap;
    opacity: 0;
    z-index: 2;
    position: absolute;
  }
}
