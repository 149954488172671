$screen-sm-min: 640px;

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

.LogoContainer {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: -21px;
}

.mobileLogo {
  display: none;

  @include sm {
    display: block;
  }
}

.textLogo {
  display: block;
  @include sm {
    display: none;
  }
}
