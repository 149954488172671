@import "../../assets/scss/variables";

.projectConteiner {
  // padding: 0 1.563rem;

  h2 {
    display: flex;
    justify-items: flex-start;
    align-items: baseline;
    font-family: $roboto-medium;
    color: #171f5e;
    font-size: $heading-size-2;
  }
  p {
    font-family: $roboto-regular;
    font-size: $heading-size-4;
    color: $black;
    margin: 0px;
  }
  span {
    font-family: $roboto-bold;
    font-size: $heading-size-4;
    color: $black;
  }

  .edit {
    width: 16px;
    height: 16px;
    margin: 5px;
    align-items: center;

    button {
      background: transparent;
      border-color: transparent;
      padding-left: 2px;
      color: #171F5E;
    }
  }
}

@media screen and (max-width: 820px) {
}
