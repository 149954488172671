@import "src/assets/scss/core";

.appsContainer {
  width: 30px;
  // height: 100%;
  font-family: $roboto-regular;
  font-size: $body-size;

  /*!important added to override the default react-bootstrap style*/
  .appsButton {
    display: flex;
    justify-content: center;
    border: $border !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: $white !important;
    box-shadow: $shadow !important;
  }

  .appsButton {
    stroke: $grey;
  }

  .appsButton:hover {
    stroke: $secondary;
  }

  .platformIcon{
    margin-left: 5px;
  }
  .mailIcon{
    margin-left: 4.5px;
  }
  .calendarIcon{
    margin-left: 2.3px;
  }
  .wikiIcon{
    margin-left: 5.3px;
  }
  .recruitmentIcon{
    margin-left: 5px;
  }
  .libraryIcon{
    margin-left: 7px;
  }
  hr{
    margin: 0;
  }
}