.update-status-modal {

    .h4,
    h5 {
        color: #171f5e !important;
        // font-weight: 600;
        font-family: "Roboto-Medium";
    }

    .modal-header {
        padding-left: 2rem !important;
        padding-top: 2rem !important;
    }

    .footer-btn {
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem 3rem 2rem;
    }

    .submission-aclaration {
        display: flex;
        align-items: center;
        margin-left: 10px;
        column-gap: 10px;

    }

    .overview-aclaration {
        display: flex;
        align-items: center;
        margin-left: 10px;
        column-gap: 10px;
        margin-top: 10px;
    }

    .addProjectUpdateForm {
        padding: 0 2rem;
        overflow-y: auto;
        max-height: 62vh;



        label {
            margin-bottom: 5px !important;
            font-weight: 600;
        }

        small {
            opacity: .6;
        }

        .selectStatusWithIcon {
            position: relative;

            svg {
                position: absolute;
                top: 10px;
                left: 10px;
                height: 20px;
            }

            select {
                padding-left: 2.2rem;
                font-family: "Roboto-Medium";

                font-weight: 600;
            }
        }

        .inDanger {
            color: red
        }

        .onTrack {
            color: green
        }

        .atRisk {
            color: orange
        }

        .statusSelects {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .overviews {
            display: grid;
            flex-wrap: wrap;
            column-gap: 3rem;
            grid-template-columns: 1fr 1fr;


            .formField {
                width: 100%;
            }
        }

        .formikError {
            color: red;
        }

        .divider {
            height: 1px;
            background-color: #eee;
            margin: 1.5rem 0;
        }



        .specificAreas {
            margin: 1.5rem 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            row-gap: 20px;

            select {
                font-family: 'FontAwesome', 'sans-serif';
            }

            .updater {
                select {
                    display: inline;
                    width: 70px;
                    margin-right: 10px;
                }


                textarea {
                    margin-top: 10px;
                }


            }
        }
    }

    @media(max-width: 990px) {

        .footer-btn {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
        }

        .overviews,
        .specificAreas {
            grid-template-columns: 1fr !important;

            .formField {
                margin-bottom: 2rem;

            }
        }
    }
}