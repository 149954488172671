.text-field {
  width: 100%;
  padding-bottom: 1%;
  font-size: 10px;
}


.search-bar {
  padding-left: 2%;
  display: flex;
  justify-content: space-between;
  width: 70%;
  border: 1px solid #e5e5e5;

  form {
    display: flex;
    align-self: center;
    width: 100%;
    height: 100%;
    input {
      padding-left: 10px;
      padding-right: 10px;
      height: 100%;
      border-style: none;
      border-color: transparent;
      font-size: 15px;
    }
    svg{
      height: auto;
    }
  }
  
  :focus { outline: none; }

  .btnsearch,
  .btnclose,
  .text-field {
    border-style: none;
    background: none;
    color: grey;
  }
}
