@import "../../assets/scss/variables";
.status-project{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px
}

.info-status-mood{
    margin: 5px
}

.info-status-satisfaction{
    margin: 5px
}

.info-status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;

    p {
      word-break: break-all;
    }

    .status-1 {
      padding-left: 5px;
      color: #28a745;
      font-weight: 500;
      font-size: $heading-size-3;
    }

    .status-2 {
      padding-left: 5px;
      color: #dc3545;
      font-weight: 500;
      font-size: $heading-size-3;
    }

    .status-3 {
      padding-left: 5px;
      color: #ffc107;
      font-weight: 500;
      font-size: $heading-size-3;
    }

    .status-outdated,
    .status-4 {
      padding-left: 5px;
      color: #6315b1;
      font-weight: 500;
      font-size: $heading-size-3;
    }
  }