.container {
  align-items: center;
  width: 761px;
  height: 402px;
  margin-left: 326px;
  margin-top: 55px;
  margin-bottom: 20px;
}

.btn.btn-link {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
}

.card {
  margin-top: 55px;
  border: 0px;
}

.list-group-item {
  padding: 0px;
}

@media only screen and (max-width: 1536px) {
  .container {
    align-items: center;
    width: 761px;
    height: 402px;
    margin-left: 168px;
    margin-top: 55px;
    margin-bottom: 20px;
  }
}
