@import "../../assets/scss/variables";
.projects-table {
  width: 100%;
  padding: 20px;
  position: relative;
}
.folder svg {
  width: 20px;
  height: 20px;
  margin-right: 10.5px;
  color: $welcome-message-primary;
}
.title-conteiner {
  display: flex;
  align-items: flex-start;
  font-family: $roboto-medium;
  font-size: 24px;
}
.project-list-search {
  display: grid;
  grid-template-columns: 40% 20%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .search-project {
    height: 116%;
  }
  ::placeholder {
    font-family: $roboto-regular;
    font-size: $small-text-size;
  }
  button {
    color: white;
    font-size: 13px;
    background: $btn-happy;
    border-radius: 5px;
    border: 0;
  }
  .buttonModal {
    width: 114px;
    height: 31px;
    text-align: center;
  }
  p {
    margin: auto;
    font-family: $roboto-regular;
    font-size: $small-text-size;
    font-weight: 400;
  }
}
.search-project {
  display: flex;
  justify-content: start;
  align-content: center;
  padding: 5px;
  width: 100%;
  color: $dark-grey;
}
.position {
  display: flex;
  justify-content: space-between;
}
.checkbox {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.checkbox label {
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: $roboto-regular;
  font-size: $small-text-size;
}
.head-table {
  font-family: $roboto-bold;
  font-size: $heading-size-2;
}
.filterName {
  margin: 25px;
}
.project-list-table {
  display: grid;
  grid-template-columns: 38% 12% 40% 10%;
  align-items: center;
  border-bottom: 2px solid #e9e9e9;
  padding: 10px;

  .info-project {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .img-project {
      height: 46px;
      margin: 12px;
    }

    .name-project {
      width: 100%;
      padding-left: 10px;
    }

    .name-project p {
      width: inherit;
    }

    .title-project {
      font-size: 18px;
      color: black;
    }

    .description-project {
      font-size: 16px;
    }
  }
  .status-project-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;


    .date {
      text-align: center;
      color: rgb(173, 171, 171);
      font-size: $heading-size-4;
    }
  }

  .puntos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    .date {
      text-align: center;
    }
  }
}
p {
  margin-bottom: 0;
}