.main-projects {
  .projects {
    font-family: "Roboto-Regular";
  }
  .flex-header {
    margin-top: 40px;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
  }
}