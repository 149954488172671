.main-container {
  height: 100%;
  padding: 50px 50px 0 0;
  font-family: Roboto-Regular;
  header {
    background-color: transparent;
  }

  .container-1-2 {
    display: flex;
    flex-flow: row wrap;
    background-color: transparent;
  }

  .flex-1 {
    display: flex;
    flex-direction: column;
    width: 75%;
    .projectOverview,
    .howWeWork,
    .teamRoles {
      border-radius: 9px;
    }
  }

  .flex-2 {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding-left: 100px;
    .latest-updates,
    .shortcuts-main {
      border-radius: 9px;
    }
  }

  .main-header {
    padding-bottom: 30px;
    border-bottom: 2px solid #f0f0f0;
  }

  .container-1-2 {
    padding-top: 30px;
  }

  .latest-updates,
  .team-roles,
  .shortcuts,
  .project-overview,
  .Project-details {
    padding-bottom: 30px;
    .how-we-work,
    .technology-stack,
    .happening-next {
      padding-bottom: 20px;
    }
  }

  .version {
    position: fixed;
    bottom: 0;
    right: 0;
    background: #F8F7F7;
    width: 136px;
    height: 45px;
  }
  .versionText{
    margin-top: 15px;
    font-family: 'Inter';
    text-align: center;
    padding: 2px;
    font-style: normal;
    font-size: 15px;
    line-height: 15px;
    color: #CFCFCF;
  }
  
  @media screen and (max-width: 820px) {
    .container-1-2 {
      flex-direction: column;
    }
    .flex-1 {
      width: 100%;
      padding-right: 0px;
    }
    .flex-2 {
      width: 100%;
      padding-right: 0px;
    }
  }
}
@media screen and (max-width: 820px) {
  .main-container {
    padding: 10px;
  }
  p {
    color: black;
  }
}

@media only screen and (min-width: 1536px) {
  .flex-1 {
    width: 70% !important; 
    padding-right: 100px !important;
  }
  .flex-2 {
    width: 30% !important;
    padding-left: 0px !important;
  }
}