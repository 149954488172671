@import "src/assets/scss/core";

.mainContainer {
  width: 100%;
  height: 100%;
  margin: 0;

  display: grid;
  grid-template-rows: 76px 89%;
  grid-template-areas:
    "topbar"
    "secondaryContent";

  .topbar {
    background-color: #000;
    width: 100%;
    height: 76px;
    grid-area: topbar;
    box-shadow: $shadow-bottom;
    z-index: 30;
    position: fixed;
    top: 0;
  }

  .secondaryContainer {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 8% 92%;
    grid-template-rows: 0.2fr 0.2fr 1.2fr 0.8fr;
    grid-area: secondaryContent;
    background-color: $bg-color-1;
    position: relative;

    .sidebar {
      z-index: 2;
      position: fixed;
    }

    grid-template-areas:
      "sidebar content"
      "sidebar content"
      "sidebar content"
      "sidebar content";
    /*
Laptop Resolution
*/
    @media only screen and (min-width: $maxMobile-width) {
      .sidebar {
        background-color: $bg-color-1;
        width: 85px;
        height: 100%;
        grid-area: sidebar;
        box-shadow: $shadow-right;
        z-index: 1;
        display: flex;
        max-width: none;
      }
    }

    /*
Desktop Resolution
*/
    @media only screen and (min-width: $maxLaptop-width) {
      .sidebar {
        background-color: $bg-color-1;
        width: 50%;
        height: 100%;
        grid-area: sidebar;
        box-shadow: $shadow-right;
        z-index: 1;
        display: flex;
        width: 85px;
        min-width: 85px;
      }
    }

    .contentContainer {
      display: grid;
      background-color: $bg-color-1;
      padding-right: 36px;
      max-height: 100%;
      overflow-y: auto;
      grid-area: content;
    }
  }
  /*
Mobile Resolution
*/
  @media only screen and (max-width: $maxMobile-width) {
    .mainContainer{
      width: 100%;
      height: 100%;
      margin: 0;
    
      display: grid;
      grid-template-rows: 60px 89% !important;
      grid-template-areas:
        "topbar"
        "secondaryContent";
    }
    .secondaryContainer {
      
      grid-template-columns: 1fr;
      grid-template-rows: 0.4fr auto 1fr;
    }
    .contentContainer {
      height: calc(100% - 30px);
      padding-right: 0px !important;
    }
    .topbar{
      height: 60px;
    }

    .sidebar {
      width: 100%;
      height: 60px;
      bottom: 0px;
      position: absolute;
    }
    .sidebar:hover {
      width: 100%;
    }
  }
}
