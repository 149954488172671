@import "../../assets/scss/variables";
.shortcuts-main {
  // padding: 0 1.563rem;
  padding-bottom: 10px;
  padding-bottom: 10px;
  h2 {
    display: flex;
    justify-items: flex-start;
    align-items: baseline;
    display: flex;
    justify-items: flex-start;
    align-items: baseline;
    font-family: $roboto-medium;
    font-size: $heading-size-2;
    color: $primary;
    .edit {
      width: 16px;
      height: 16px;
      margin: 5px;
      align-items: center;

      button {
        background: transparent;
        border-color: transparent;
        padding-left: 2px;
        color: #171f5e;
      }
    }
  }

  ul {
    padding-left: 1.18rem;
  }

  .shortcut-name {
    color: $primary;
    font-size: 15px;
  }
  li {
    padding-left: 5px;
  }
  a {
    font-size: $heading-size-4;
    color: #16448a;
  }

  .form-group {
    width: 100%;
    padding-bottom: 5px;
  }

  .shortcutUrl {
    width: 100%;
    resize: none;
    margin-bottom: 5px;
  }

  .shortcutName {
    width: 100%;
    resize: none;
    margin-bottom: 5px;
  }

  .buttons {
    position: relative;
    width: 100%;
  }

  #cancelButton {
    position: absolute;
    right: 83px;
    top: 0;
    font-family: $roboto-regular;
    font-size: 13px;
    width: 83px;
    height: 31px;
    border-radius: 5px;
    color: #ff0101;
    border-color: #ff0101;
    background-color: #ffffff;
    margin-right: 11px;
  }

  #confirmButton {
    position: absolute;
    right: 0;
    top: 0;
    font-family: $roboto-regular;
    font-size: 13px;
    width: 83px;
    height: 31px;
    border-radius: 5px;
    background-color: #5ac775;
    color: #ffffff;
    border-color: #5ac775;
  }
}
