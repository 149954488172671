@import "fonts";

/*-----COLORS-----*/

// Primary color
$primary: #171f5e !default;
$secondary: #5ac775 !default;
$terciary: #1263dd !default;

// Grey color
$grey: #b0b0b0 !default;
$light-grey: #fafafa;
$dark-grey: #9c9c9c;
$another-grey: #f0f0f0;
// Other colors
$black: #000 !default;
$white: #fff !default;

//Message solors ---There maybe change, no info related---
$alert: #ff0000;
$success: #3faa58;
$info: $terciary;
$warning: #ffb800;

// Background color
$bg-color-1: $white;
$bg-color-2: $light-grey;
$bg-color-3: #1263dd2b;
$bg-color-4: #86e29e2b;

// BTS Logo colors
$bts-logo-font-color-1: #121f5c;
$bts-logo-font-color-2: #38b872;
$bts-logo-color-1: $primary;
$bts-logo-color-2: $secondary;

//Icon colors
$icon-color: $grey;
$icon-color-hover: $secondary;

// Font colors
$heading-text-color: $primary;
$body-text-color-1: $black;
$body-text-color-2: $grey;
$body-text-color-3: $dark-grey;
$small-text-color: $terciary;
$placeholder: $grey;

// Calendar days colors
$holiday-fill: #1662d2;
$holiday-border: #124ea6;
$illness: #d66cd1;
$off: #ff0101;

// Welcome messages colors
$welcome-message-primary: $primary;
$welcome-message-secondary: #9c9c9c;

// Notifications colors
$notification-red: $off;
$notification-light-red: #ff010126;
$notification-orange: #f26600;
$notification-light-orange: #f2660026;
$notification-blue: #217dd1;
$notification-light-blue: #217dd126;
$notification-yellow: $warning;
$notification-light-yellow: #ffb80026;

//Tickler colors
$btn-happy: #38b872;
$btn-sad: #6d6d6d;
$btn-delete: #d11a1a;
$btn-finish: $terciary;
$tickler-heading-starter: $primary;
$tickler-heading-unstopped: $warning;
$tickler-heading-stopped: $primary;

// Clock colors
$clock-heading-1: $primary;
$clock-heading-2: $terciary;
$clock-body-1: $black;
$clock-body-2: $btn-sad;
$clock-home-icon: $btn-happy;

//Auxiliary colors
$container-grey: #c4c4c4;

/*-----FONTS-----*/

// Font types
$roboto-light: "Roboto-Light", sans-serif;
$roboto-light-italic: "Roboto-LightItalic", sans-serif;
$roboto-regular: "Roboto-Regular", sans-serif;
$roboto-medium: "Roboto-Medium", sans-serif;
$roboto-bold: "Roboto-Bold", sans-serif;

// Font sizes
$heading-size-1: 2.5rem; //40px
$heading-size-11: 1.5rem; //24px
$heading-size-2: 1.25rem; //20px
$heading-size-3: 1.125rem; //18px
$heading-size-4: 1rem; //16px
$body-size: 0.875rem; //14px
$small-text-size: 0.75rem; //12px
// $heading-size-1: 1.9rem;
// $heading-size-2: 1rem;
// $small-text-size: 0.7rem;
$phrase-size: 1.1rem;
$subtitle-size: 2rem;

/*-----BOX SHADOW-----*/
$shadow: 0px 0px 22px rgba(197, 14, 14, 0.04);
$shadow-bottom: 0px 6px 8px rgba(0, 0, 0, 0.04);
$shadow-right: 6px 0px 8px rgba(0, 0, 0, 0.04);
$shadow-dropdown: 0px 0px 12px rgba(0, 0, 0, 0.11);

/*-----WIDTH/HEIGHT SIZE-----*/
$maxMobile-width: 821px;
$fixed-width: 320px;
$minMobile-height: 291px;
$maxLaptop-width: 1366px;
$minLaptop-height: 768px;
$maxMobilePhone-width: 550px;
$minMegaMonitor-width: 2651px;
$minMonitor-width: 2600px;
$maxMonitor-height: 1441px;
$minMonitor-height: 1081px;
$minstandardMonitor-width: 822px;
$hdmonitor-width: 1920px;
$qhdmonitor-width: 2550px;

/*-----SPACING-----*/
$letter-spacing: 0.02em;
$line-height: 99.19%;

/*-----BORDER-----*/
$border: none;
$border-radius: 9px;

/*-----WELCOME MESSAGE-----*/
$welcome-message-primary: #171f5e;
$welcome-message-secondary: #101010;
$select-border: #ababab;
$skills-selected: #424eaf;

/*-----PADDING-----*/
$padding-left-home: 9.5vw;
$padding-right-home: 9.5vw;
$padding-top-home: 0.5vh;

/*-----What is going on-----*/
$wigo-posted: #595959;
$wigo-links-color: #16448a;
