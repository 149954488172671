@import "src/assets/scss/core";
.content-container {
  display: flex;
  flex-direction: row;
  .icon-container:hover {
    color: $secondary;
  }
  .feedback-container {
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: $white;
    color: $bts-logo-color-1;
    line-height: 2em;
    border-radius: 9px;
    box-shadow: $shadow-dropdown;
    transition: 0.3s ease-in-out all;
    margin-top: 5px;
    margin-left: 40px;
    flex-direction: column;
    width: 400px;
    position: fixed;
    bottom: 65px;
    .title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-left: 20px;
      font-weight: 700;
    }
    .text-area {
      width: 93%;
      height: 117px;
      color: #ababab !important;
      margin: 10px;
      padding-left: 20px;
      padding-top: 10px;
      border: solid 1px #ababab;
      resize: none;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.04em;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      .btn-cancel {
        border: 1px solid red !important;
        background-color: #fff !important;
        color: red !important;
      }
      .btn,
      .btn-cancel {
        height: 30px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        font-size-adjust: 100%;
        border: none;
        background-color: #38B872;
        color: white;
        border-radius: 5px;
        margin: 10px;
      }
    }
  }
}

@media screen and (max-width: $maxMobile-width) {
  .content-container {
    .icon-container {
      height: 100%;
      margin-left: 20px;
    }
    .feedback-container {
      right: 20px;
      bottom: 50px;
    }
  }
}

@media only screen and (max-width: 1536px) {
  .content-container {
    .feedback-container {
      bottom: 50px;
    }
  }
}
