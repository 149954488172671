.status {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.In-danger {
  span {
    color: #ff0000;
    font-weight: bold;
  }
  .Mui-checked {
    background-color: rgba(255, 0, 0, 0.2) !important;
  }
}

.At-risk {
  span {
    color: #ffb800;
    font-weight: bold;
  }
  .Mui-checked {
    background-color: rgba(255, 184, 0, 0.2) !important;
  }
}

.On-track {
  span {
    color: #3faa58;
    font-weight: bold;
  }
  .Mui-checked {
    background-color: rgba(63, 170, 88, 0.2) !important;
  }
}

.Out-dated {
  span {
    color: #9510ac;
    font-weight: bold;
  }
  .Mui-checked {
    background-color: rgba(149, 16, 172, 0.2) !important;
  }
}

.Archived {
  span {
    color:#696969;
    font-weight: bold;
  }
  .Mui-checked {
    background-color: rgba(107, 105, 107, 0.29) !important;
  }
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 5px !important;
}
