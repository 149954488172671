@import "src/assets/scss/core";

$breakpoints: (
  sm: 550px,
  md: 768px,
  lg: 1024px,
);

@mixin media-up($breakpoint) {
  @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

.whatIsGoingOnContainer {
  width: 100%;
  height: 100%;
  background: $white;
  background: $bg-color-1;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.04);
  border-radius: $border-radius;

  .whatIsGoingOnTitle {
    font-family: $roboto-medium;
    color: $heading-text-color;
    font-size: 22px;
    padding: 30px 30px 30px 40px;
    margin: 0;
    box-shadow: 0px 4px 5px -4px rgba(0, 0, 0, 0.22);
    border-radius: 9px 9px 0px 0px;
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding: 22px;
    padding-bottom: 11px;
    .article {
      display: flex;
      align-items: flex-start;
      padding: 16px;

      .section1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-basis: 18%;

        .articlePicture {
          padding-top: 2.5%;
          width: 50px;
          display: flex;
          justify-content: center;
        }

        .articlePicture figure {
          width: 53px;
          height: 53px;
        }

        .articlePicture img {
          width: 100%;
          height: auto;
          border-radius: 2px;
        }

        .postedOn {
          font-family: $roboto-regular;
          font-size: 10px;
          line-height: $line-height;
          text-align: center;
          letter-spacing: $letter-spacing;
          color: $wigo-posted;
        }

        @supports (object-fit: cover) {
          .articlePicture img {
            height: 100%;
            object-fit: cover;
            object-position: center center;
          }
        }
      }

      .section2 {
        padding-left: 15px;
        flex-basis: 82%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        letter-spacing: $letter-spacing;
        line-height: $line-height;
        padding-top: 0;

        .articleTitle {
          font-family: $roboto-bold;
          font-style: normal;
          font-size: 14px;
          line-height: $line-height;
          letter-spacing: $letter-spacing;
          color: $black;
        }
        .articleText {
          font-family: $roboto-regular;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: $line-height;
          letter-spacing: $letter-spacing;
          color: $black;
        }

        .articleReadMore {
          padding-top: 7px;
          font-family: $roboto-regular;
          font-weight: 300;
          font-size: 15px;
          line-height: $line-height;
          letter-spacing: 0.04em;
          text-decoration-line: underline;
        }
      }
    }
  }

  .viewAll {
    display: flex;
    padding-bottom: 11px;
    padding-right: 22px;
    margin: 30px;
    align-items: baseline;
    justify-content: flex-end;
    font-family: $roboto-regular;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: $wigo-links-color;
  }

  @include media-up(sm) {
    .articleTitle,
    .articleText,
    .articleReadMore {
      font-size: 95%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .whatIsGoingOnContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .articles {
      display: flex;
      flex-direction: column;
    }
  }
}
