@import "src/assets/scss/core";

.topbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $bg-color-1;
  box-shadow: $shadow-bottom;
  position: relative;
  z-index: 10000;

  .leftContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 84px;
    margin-left: -1px;
    padding: 1px 0 0 3px;
  }

  .midContainer {
    margin-left: 62px;
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    right: 350px;
    max-width: 200px;
  }

  .rightContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    margin-left: auto;
    right: 30px;
    padding-top: 12px;
    // .languageSelector{

    //   margin-right: 10%;
    // }

    // The component isn't the definitive, because of that this style is only to demo
    .dropClock {
      position: relative;
      right: 575px;
    }

  }

  .ticklerIcon{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 2.5px;
  }

  .clockIcon {
    padding-bottom: 1px;
  }
  .ticklerIcon:hover{
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 2.5px;
    stroke-width: 0.5px;
    stroke: $secondary;
  }

  .clockIcon:hover {
    padding-bottom: 1px;
    stroke-width: 0.5px;
    stroke: $secondary;
  }
}

/*
Laptop Resolution
*/
@media screen and (max-width: $maxLaptop-width) {
  .topbarContainer {
    .rightContainer {
      gap: 30px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .btnLang {
    margin-right: 5px;
  }
}

@media only screen and (max-width: $maxMobile-width) {
  .topbarContainer {
    .leftContainer{
      transform: scale(0.8);
    }
    .midContainer {
      visibility: hidden;
    }
    .rightContainer{
      bottom: 5px;
      gap: 0px;
      .dropClock {
        position: relative;
        right: 210px;
      }
    }
  }
}