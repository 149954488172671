@import "../../assets/scss/variables";

.project-files-container {
  h2 {
    font-size: clamp(1rem, 1vw, 2rem);

    // display: flex;
    // justify-items: flex-start;
    // align-items: baseline;
    padding-bottom: 10px;
    font-family: $roboto-medium;
    font-size: $heading-size-2;
    color: $primary;
  }

  padding: 0px;

  .file-rejected {
    color: red;
    opacity: 0.7;
    margin: 15px 0;
  }

  .files-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    column-gap: 50px;
    row-gap: 40px;
    flex-wrap: wrap;
    margin: 20px 0;

    .file-icons {
      position: relative;
      position: relative;
      display: flex;
      align-items: center;

      svg:nth-child(2) {
        position: absolute;
        bottom: 5px;
        right: -10px;
      }
    }

    & > div {
      display: flex;
      column-gap: 15px;
      max-width: 100%;
      color: #000000;

      p:first-of-type {
        font-weight: bold;
      }
    }

    .doc-menu {
     div:nth-child(1){
      top: 16px;
      right: -60px;
     }
    }
  }

  .isDragActive {
    display: flex;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 2px solid #39cf39;
    opacity: 0.5;
    border-style: dashed;
  }

  .reject {
    border-color: red !important;
  }

  .to-upload-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    span {
      svg:hover {
        color: red;
      }
    }

    .file-ready {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }
  }

  .action-btn {
    margin-top: 2rem;
    display: flex;
    column-gap: 10px;
    justify-content: center;
  }

  .box{
    width: 100%; 
  }
  .many-files{
    color: red;
    padding-top: 10px;
    text-align: center;
  }
}
