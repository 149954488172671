@import "src/assets/scss/core";

.players-mood-table {
  font-family: "Roboto-Regular";
  table {
    width: 100%;
  }
  thead td {
    padding-bottom: 8px;
    color: #6c757d;
  }
  .player-row {
    border-bottom: 2px solid #ced4da;
    height: 57px;
    .name-column {
      width: 42%;
    }
    .role-column {
      width: 42%;
    }
    .mood-column {
      width: 16%;
    }
    td {
      padding: 4px;
      font-size: 14px;
    }
    .player-img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .capital-letters {
      min-height: 25px;
      min-width: 25px;
      border-radius: 50%;
      margin-right: 6px;
      background-color: #ced4da;
      text-align: center;
      p {
        padding: 4px 0 0 0;
        font-size: 12px;
      }
    }
    .mood-icon {
      cursor: pointer;
      .tooltip {
        color: #6c757d;
      }
    }
  }
}
