.add-project-modal {
  .modal-content {
    border: none;
    box-shadow: #d6d6d6 0 2px 6px;
    font-family: "Roboto-Regular";
    width: 85%;
  }

  .modal-header {
    padding: 1rem 1.5rem 1rem 2.5rem;
    box-shadow: #d6d6d6 0 1px 2px;
  }

  .modal-title {
    color: #171f5e;
    font-weight: 700;
    font-size: 21px;
    padding-top: 1.5rem;
  }

  .modal-body {
    padding: 2.5rem;
    padding-bottom: 1rem;
    font-weight: 600;
    font-size: 15px;
  }

  .modal-footer {
    border-top: 0;
    padding: 2.5rem;
    padding-top: 0;
  }

  .form-control {
    border-radius: 2px;
    padding: 0.375rem 1.1rem;
    font-size: 13px;
    width: 100%;
  }

  .input-with-icon {
    .react-datepicker__input-container input {
      padding: 0.375rem 1.25rem;
      width: 100%;
    }
    label {
      width: 100%;
    }
  }

  .add-project-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    row-gap: 0.8rem;

    .form-field {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      margin-bottom: 1rem;

      [list]::-webkit-calendar-picker-indicator {
        display: none !important;
      }
    }

    .formik-error {
      color: red;
    }

    .icon-outside-input {
      display: flex;
      column-gap: 10px;
      align-items: center;

      input {
        max-width: 40%;
        color: #171f5e;
        font-size: 12px;
        font-weight: 600;
        padding-right: 1rem;
      }

      span {
        color: #171f5e;
        font-size: 13px;
      }
    }

    .input-with-icon {
      display: flex;
      align-items: center;
      position: relative;

      svg {
        position: absolute;
        right: 15px;
        top: 9px;
      }
    }
  }

  .other-contract-type {
    padding-top: 1.8rem;
  }

  .close-icon {
    border: none;
    background-color: transparent;
  }

  #category,
  #contract-type {
    background-size: 10px 10px;
  }

  .select-client {
    padding: 0;
    font-size: 13px;
    font-weight: normal;

    svg {
      height: 15px;
      width: 15px;
      fill: #343a40 !important;
    }

    .css-1s2u09g-control {
      border-radius: 2px !important;
      min-height: auto;
    }

    .css-1s2u09g-control:hover {
      border-color: hsl(0, 0%, 80%);
    }

    .css-tlfecz-indicatorContainer {
      padding: 9px;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-319lph-ValueContainer {
      padding-left: 1rem;
    }

    .css-1pahdxg-control {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe !important;
      outline: 0 !important;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253/25%) !important;
    }
  }

  @media (max-width: 990px) {
    .footer-btn {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1rem;
    }

    .add-project-form {
      grid-template-columns: 1fr !important;

      .form-field {
        margin-bottom: 2rem;
      }
    }
  }
}
