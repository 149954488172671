@import "../../../assets/scss/core.scss";

.notificationsContainer {
  font-family: $roboto-regular;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  //the Notification icon
  .notificationsIcon {
    width: 21px;
    :hover {
      cursor: pointer;
    }
    .newNotifications {
      display: flex;
      position: relative;
      top: 5px;
      transform: scale(1.8);
      right: -90%;
    }
  }

  hr {
    height: 2px;
    hr:last-child {
      color: red;
    }
  }

  .allNotifications {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    font-size: 100%;
    letter-spacing: 0.04em;
    padding-top: 6%;
    color: #16448a;
    text-decoration-line: underline;
  }

  //Notifications and mark all as read
  .notificationsTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    top: 4%;
    padding: 0 6%;

    h3 {
      font-weight: bold;
      font-size: 150%;
      color: #171f5e;
      letter-spacing: 0.04em;
    }

    h5 {
      font-size: 100%;
      letter-spacing: 0.04em;
      color: #16448a;
      text-decoration-line: underline;
    }
  }
}
//scss for each notification
.singleNotification {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 105.5%;
  min-height: 79px;
  right: 10px;
  text-align: left;
  letter-spacing: 0.04em;
  padding: 3%;
  font-size: 0.75rem;
  margin: -1.75% 0;
  color: $body-text-color-1;

  .closeNotificationButton {
    background-color: transparent;
    color: #aaaaaa;
    border: none;
  }

  .notificationMessage {
    display: flex;
    font-weight: bold;
    width: 80%;
    margin-left: 13%;
    line-height: 150%;
  }

  .titleNotification {
    display: flex;
    justify-content: space-between;
    font-size: $body-size;
    margin-top: -4%;
    color: #595959;

    .date {
      display: flex;
      position: fixed;
      margin-left: 1%;
      margin-top: 10px;
      font-size: $body-size;
    }

    .seenDate {
      display: flex;
      align-items: center;
      margin-left: 8%;
      margin-top: 5px;
    }
  }
}

.notificationTypeIcon {
  font-size: 20px;
}
.critical {
  background-color: $notification-light-red;

  .notificationTypeIcon {
    color: $notification-red;
  }
}

.warning {
  background-color: $notification-light-yellow;

  .notificationTypeIcon {
    color: $notification-yellow;
  }
}

.informational {
  background-color: $notification-light-blue;

  .notificationTypeIcon {
    color: $notification-blue;
  }
}
// The component isn't the definitive, because of that this style is only to demo
.dropd {
  width: 375px;

  position: fixed;
  right: 10.5%;
}

@media only screen and (max-width: 576px) {
  .dropd {
    width: 300px;
    right: 75px;
  }
  .titleNotification {
    margin-left: 10px;
  }
  .seen {
    position: relative;
    left: -10px;
  }

  .singleNotification {
    width: 107.3%;
  }
}
/*
Laptop Resolution
*/
@media only screen and (min-width: $maxMobile-width) and (max-width: $maxLaptop-width) {
  .dropd {
    width: 360px;
    right: 8%;
  }
  .titleNotification {
    margin-left: 10px;
  }
  .seen {
    position: relative;
    left: -10px;
  }
}

@media only screen and (min-width: $hdmonitor-width) {
  .dropd {
    right: 6%;
  }
}

@media only screen and (min-width: $qhdmonitor-width) {
  .dropd {
    right: 4%;
  }
}
