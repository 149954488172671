.edit-project-modal {
  .modal-content {
    border: none;
    box-shadow: #d6d6d6 0 2px 6px;
    font-family: "Roboto-Regular";
    width: 85%;
  }

  .modal-header {
    padding: 1rem 1.5rem 1rem 2.5rem;
    box-shadow: #d6d6d6 0 1px 2px;
  }

  .modal-title {
    color: #171f5e;
    font-weight: 700;
    font-size: 21px;
    padding-top: 1.5rem;
  }

  .modal-body {
    padding: 2.5rem;
    padding-bottom: 1rem;
    font-weight: 600;
    font-size: 15px;
  }

  .modal-footer {
    border-top: 0;
    padding: 2.5rem;
    padding-top: 0;
  }

  .form-control {
    border-radius: 2px;
    padding: 0.375rem 1.1rem;
    font-size: 13px;
    width: 100%;
  }

  .input-with-icon {
    .react-datepicker__input-container input {
      padding: 0.375rem 1.25rem;
      width: 100%;
    }
    label {
      width: 100%;
    }
  }

  .edit-project-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    row-gap: 0.8rem;

    .form-field {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      [list]::-webkit-calendar-picker-indicator {
        display: none !important;
      }
    }

    .formik-error {
      color: red;
    }

    .icon-outside-input {
      display: flex;
      column-gap: 10px;
      align-items: center;

      input {
        max-width: 40%;
        color: #171f5e;
        font-size: 12px;
        font-weight: 600;
        padding-right: 1rem;
      }

      span {
        color: #171f5e;
        font-size: 13px;
      }
    }

    .input-with-icon {
      display: flex;
      align-items: center;
      position: relative;

      svg {
        position: absolute;
        right: 15px;
        top: 9px;
      }
    }
  }

  .other-contract-type {
    padding-top: 0.8rem;
  }

  .close-icon {
    border: none;
    background-color: transparent;
  }

  #category,
  #contract-type {
    background-size: 10px 10px;
  }

  @media (max-width: 990px) {
    .footer-btn {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1rem;
    }

    .edit-project-form {
      grid-template-columns: 1fr !important;

      .form-field {
        margin-bottom: 2rem;
      }
    }
  }
}
