html,
body {
  height: 100%;
  width: 100%;
  /* Safari/Chrome, other WebKit */
  -webkit-box-sizing: border-box;
  /* Firefox, other Gecko */
  -moz-box-sizing: border-box;
  /* Opera/IE 8+ */
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: var(--font-family-sans-serif); */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  /* --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #38b872;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --background-layout: #f7f7f7;
  --bts-blue: #171f5e;
  --bts-green: #5ac674;
  --bts-dark-green: #3ea859;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace; */
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(230, 223, 223);

  border-radius: 20px;
  /* Change this to match navbar */
  border: 8px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(170, 164, 164);
}

/*Styles for every sweet alert confirm/cancel modal*/
.main-dialog{
  padding: 2em 1em 0 !important;
  color: #171f5e !important;
  font-size: 1.3em !important;
  font-weight: 500 !important;
}
.secondary-dialog {
  margin: 0.8em 1.2em 0.3em !important;
  color: #171f5e !important;
}
.actions-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
  padding-bottom: 20px !important;
  width: 60% !important;
}
.action-button {
  width: 35%;
  appearance: none;
  backface-visibility: hidden;
  background-color: #38b872 !important;
  border-radius: 5px !important;
  border-style: none !important;
  box-sizing: border-box;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 14px !important;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 5px 20px;
  margin: 0px 0px 0px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none !important;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.action-button:hover {
  background-color: #1e8449 !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition-duration: 0.35s !important;
  box-shadow: rgba(39, 174, 96, 0.2) 0 6px 12px !important;
}

.action-button:active {
  transform: translateY(2px) !important;
  transition-duration: 0.35s !important;
}

.secondary-action-button {
  width: 35%;
  background-color: initial !important;
  border: 1px solid #ff4742 !important;
  border-radius: 5px !important;
  box-sizing: border-box;
  color: #ff4742 !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.4;
  overflow: hidden;
  outline: 0;
  padding: 5px 20px;
  margin: 0px 6px 0px 0px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  transition: all 0.3s;
  user-select: none !important;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
}

.secondary-action-button:hover,
.secondary-action-button:active {
  background: #ff4742 !important;
  color: #fff !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition-duration: 0.35s !important;
  box-shadow: rgba(174, 39, 39, 0.15) 0 4px 9px !important;
}

.secondary-action-button:active {
  transform: translateY(2px) !important;
  transition-duration: 0.35s !important;
}
