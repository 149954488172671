@import "../../assets/scss/variables";

.projectHeader {
  display: grid;
  grid-template-columns: 70% 30%;
  border-radius: 9px;
}
.mainData {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  row-gap: -10px;
}
.folder svg {
  width: 20px;
  height: 20px;
  margin-right: 10.5px;
  color: $welcome-message-primary;
}
h1 {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
  font-family: $roboto-medium;
  font-size: 24px;
  color: $welcome-message-primary;
}
.projectKey {
  font-family: $roboto-medium;
  font-size: $heading-size-2;
  color: #595959;
}
.statusData {
  border-right: 4px solid var(--bts-blue);
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  font-family: $roboto-regular;
  font-size: $heading-size-2;
  row-gap: 10px;
  color: #595959;
  .status {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .updateTitle {
    display: flex;
    flex-direction: row;
  }
}
.statusIcon svg {
  height: 20px;
  width: 20px;
}
.projectKeySpan {
  font-family: $roboto-medium;
  font-size: $heading-size-2;
  color: #595959;
}

h5 {
  font-family: $roboto-light;
  font-size: $heading-size-2;
  color: #595959;
}
.updateHeader {
  font-family: $roboto-bold;
  font-size: $heading-size-2;
  color: #595959;
  padding-right: 5px;
}
