$maxImagesNumber: 20;

.players {
  display: flex;
  flex-flow: wrap;
  //verflow-x: auto;
  .players-container {
    position: relative;
    padding: 40px;
  }
  .img-player,
  .total,
  .capital-letters,
  .foo {
    position: absolute;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    top: 10px;
    cursor: auto;
  }

  .foo {
    top: 3px;
  }
  .total,
  .capital-letters {
    background-color: #ccd1d1;
    text-align: center;
    padding-top: 12px;
    padding-left: 5px;
    color: rgb(82, 81, 81);
    font-family: "Roboto-Medium";
  }
  .capital-letters {
    padding: 0;
    padding-top: 10px;
    font-size: 18px;
    letter-spacing: 1px;
  }
  .total {
    color: white;
    font-size: 14px;
    padding-top: 14px;
    left: 120px;
    top: 13px;
    z-index: 0;
    font-weight: 500;
  }
  .total:hover {
    cursor: default;
  }
  .capital-letters:hover {
    background-color: #b4b3b3;
  }
  .playerOpened {
    filter: grayscale(100%);
  }
  .playerClosed {
    filter: grayscale(0%);
  }
}
.player-mood {
  position: absolute;
  right: 4px;
  top: 42px;
}

@media (max-width: 600px) {
  .total {
    left: 120px;
  }
}
