// Roboto @font-faces
@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Light-Italic';
  src: url('../fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}
