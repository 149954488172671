@import "../../assets/scss/variables";
.latest-updates-main {
  .updates-header {
    h2 {
      font-family: $roboto-medium;
      font-size: $heading-size-2;
      color: $primary;
      font-weight: 500;
      margin: 0;

      svg {
        margin-left: 10px;
        cursor: pointer;
        color: $primary;
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  padding-top: 1.563rem;

  button {
    font-size: $small-text-size;
    padding: 0px !important;
    box-shadow: none;
  }

  #singleViewer.btn.btn-link {
    width: 160px;
  }

  .updates-list {
    margin-top: -10px;
  }

  .update {
    display: flex;
    flex-direction: column;
    row-gap: 0.688rem;
    margin: 30px 0;
    font-size: 0.875rem;
    border-left: 3px solid #1263dd;
    padding-left: 1.18rem;

    .update-areas {
      display: flex;
      column-gap: 15px;
      font-size: 14px;
    }

    .areas-status {
      display: flex;
      align-items: flex-end;
      column-gap: 5px;

      div {
        display: flex;
        column-gap: 5px;

        svg {
          width: 15px;
        }
      }
    }

    .status-info {
      display: flex;
      align-items: flex-end;
      column-gap: 10px;
      justify-content: space-between;

      span {
        font-weight: 600;
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: $heading-size-4;
      color: black;
      word-wrap: break-word;
    }
  }

  .border-status-1 {
    border-color: #28a745;
  }

  .border-status-2 {
    border-color: #ff0101;
  }

  .border-status-3 {
    border-color: #ffc107;
  }

  .status-1 {
    color: #28a745;
    margin-left: 5px;
  }

  .status-2 {
    color: #ff0101;
    margin-left: 5px;
  }

  .status-3 {
    color: #ffc107;
    margin-left: 5px;
  }

  .status-outdated,
  .status-4 {
    color: #6315b1;
    margin-left: 5px;
  }
}
