@import "src/assets/scss/core";
.container {
  display: grid;
  grid-template-columns: 1fr 8fr repeat(3, 2fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 0 20px 0;

  padding-left: 1em;
  padding-right: 1rem;
  margin: 1rem;
}

.img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.author {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .authorPhoto {
    width: 45px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.img {
  grid-area: 1 / 1 / 2 / 2;
  object-fit: contain;
  padding: 10px;
  aspect-ratio: 1/1;
}
.titleAndResume {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
}
.portraid {
  grid-area: 1 / 3 / 2 / 4;
}
.authorName {
  grid-area: 1 / 4 / 2 / 5;
}
.moreIcon {
  // grid-area: 1 / 5 / 2 / 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
  .drop {
    transform: scale(0.9);
    position: relative;
    right: 6vw;
    float: right;
  }
}
.title {
  display: flex;
  justify-content: flex-start;
  font-family: "Roboto-Medium", sans-serif;
  color: #171f5e;
  font-size: 1.125rem;
  padding: 5px 0 5px;
  margin: 0;
  border-radius: 9px 9px 0px 0px;
}

@media screen and (max-width: $maxMobile-width) {
  .container {
    display: flex;
    flex-direction: row;

    .img img {
      min-width: 150px;
      width: 150px;
      height: 150px;
    }

    .date {
      width: 100%;
    }

    .author {
      .authorPhoto {
        margin-right: 5px;
      }
    }
  }
}
@media screen and (max-width: $maxMobilePhone-width) {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
    .img > img {
      width: 100%;
      height: min-content;
    }
    .title {
      display: flex;
      justify-content: center;
    }
    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 70px;
      margin-right: 10px;
    }

    .author {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 50px;
      margin-bottom: 30px;

      .authorName > p {
        display: flex;
        text-align: justify;
        text-justify: inter-word;
      }
    }
  }
  .moreIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }

    .drop {
      transform: scale(0.9);
      position: relative;
      right: 6vw;
      float: right;
    }
  }

  .swal2-styled.swal2-confirm {
    background-color: red !important;
  }
}

@media screen and (max-width: $maxMobile-width) {
  .moreIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }

    .drop {
      transform: scale(0.9);
      position: relative;
      right: 15vw;
      float: right;
    }
  }
}
